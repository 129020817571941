.projection{
  font-size: 60px;
  margin-top:320px;
  margin-bottom: -50px;
  font-weight: 800;
  color: white;
}

.menuLeads{
  margin-top:100px;
}

.menuLeads ul li{
  list-style: none !important;
}

.buttonLeads{
  background-color: #414141;
  color:#fff;
  margin-left: -40px;
  border:none !important;
  margin-bottom:20px;
  outline: 1px solid #fff;
  width: 400px;
  box-shadow: 
  0 0 1.2rem #d5385a,
  0 0 0.8rem #d5385a,
  0 0 0.6rem #9D2727,
  0 0 0.5rem #9D2727,
  0 0 0rem #9D2727,
  inset 0 0 0 #9D2727;
  height: 40px;
  font-size: 20px;
  border-radius: 10px;
}

.buttonLeads i{
  font-size: 18px;
  color:#d5385a;
}


.metaTitle{
  font-size: 50px;
  margin-top:50px;
  margin-bottom: -50px;
  font-weight: 800;
  color: white;
}

.menuMetas ul li{
  list-style: none !important;
  display: inline-block;
}

.metaBtn{
  height: 300px;
  width: 300px;
  margin-left:30px;
  box-shadow: 
              0 0 0.6rem #9D2727,
              0 0 0.5rem #9D2727,
              0 0 0rem #9D2727,
              inset 0 0 0 #9D2727;
  background-color: #ededed;
  outline: 1px solid #fff;
  border-radius: 20px;
  border:none !important;
  color:#EF232C;
  margin-top:80px;
  font-size: 20px;
  font-weight: 600;
}

.metaFaturamentoTitle{
  font-size: 50px;
  margin-top:0px;
  margin-bottom: -50px;
  font-weight: 800;
  color: #EF232C;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
}

.chartPie{ 
  margin-top:40px !important;
}

.legendButton{
  background-color:#d5385a;
  height: 20px;
  width: 20px;
  outline: none !important;
  border: none !important;
}
.legenda{
  color:#d5385a;
  font-size: 26px;
}

.legendafb{
  color:#d5385a;
  font-size: 20px;
  padding-top:10px;
  margin-top:30px;
  text-align: center;
}

.legendButtonfb{
  background-color:#f7325a;
  border: none !important;
  height: 20px;
  width: 20px;
}

.legendafb i{
  color: #d5385a !important;
}

.legendButtong{
  background-color:#fe799d;
  border: none !important;
  height: 20px;
  width: 20px;
}

.legendaRadial{
  color: #d5385a;
  font-size: 20px;
  text-align: center;
}

.legendaRadial2{
  color: #d5385a;
  font-size: 20px;
  text-align: center;
}

.legendButtontot{
  background-color: #fe799d;
  border:none !important;
  width: 20px;
  height: 20px;
}
.legendButtonindi{
  background-color: #f7325a;
  border:none !important;
  width: 20px;
  height: 20px;
}


.custom-tooltip{
  color:#fff;
  font-weight: 600;
}
.legendascpl{
  color:#d5385a;
  font-size: 20px;

}
.legendButtonCplA{
  background-color: #f7325a;
  border:none !important;
  width: 20px;
  height: 20px;

}

.legendButtonCplFb{
  background-color: #fe94b6;
  border:none !important;
  width: 20px;
  height: 20px;
  margin-left: 18px;
}
.legendButtonCplG{
  background-color:#fe799d ;
  border:none !important;
  width: 20px;
  height: 20px;
  margin-left: 18px;
}

#funil{
  margin-left:120px;
  margin-top:90px; 
  width: 400px;
}

.funnel{
  background-color: #414141;
}


.titulofunil{
  color:white;
  font-weight: 600;
  margin-top:65px;
  padding-bottom: .3rem;
  font-size: 15px;
  margin-left: -130px;
}

.tablefb table{
  width: 400px;
  margin-left: -130px;
}

.tablefb tr{
  min-width: 160px !important;
}

.topp{
  background-color: #3678F1;
  color:#fff;
  text-align: center;
}

.percent{
  background-color: #d9cccd;
  opacity: 0.8;
  font-weight: 500;
  text-align: center;
  padding:0;
}

.descoberta{
  background-color:  #E63026;
  text-align: center;
  padding:0;
}
.leads{
  background-color: #FDB003;
  text-align: center;
  padding:0;
}
.agendamento{
  background-color:  #249B41;
  text-align: center;
  padding:0;
}

.comparecimento{
  background-color: #00AA9F;
  text-align: center;
  padding:0;
}

.fechamento{
  background-color: #00CDD9;
  text-align: center;
  padding:0;
}

.tabelaGoogle table{
  margin-left: -190px;
  width: 280px;
}

.toppGoogle{
  background-color:  #3678F1;
  color: #fff;
  text-align: center;
  
}

.titulofunilG{
  color:white;
  font-weight: 600;
  margin-top:65px;
  font-size: 15px;
  margin-bottom: 0;
  padding-bottom: .3rem;
  margin-left: -190px;
}

.titulofunilT{
  color:#d5385a;
  font-weight: 600;
  margin-top:-150px;
  font-size: 15px;
  margin-bottom: 10px;
  margin-left: -130px;

}

.tableTicket table{
  width: 700px;
  margin-left: -130px;
}

.titulomenor{
  background-color:#3678F1;
  opacity: 1 !important;
  text-align: center;
}
.tituloprojetado{
  background-color:#3678F1;
  opacity: 1 !important; 
  text-align: center;
}
.hdFunil{
  margin-top:-80px;
  margin-left: 280px;
  max-width: 200px;
}
#bgPrint{
  background-image: url('../../assets/img/fundo3.png');
  background-repeat: no-repeat;
  background-size:100% 1600px;
  background-position: center top;
  font-size: 20px;
  height: 1650px;
}

.tituloMetas{
  color: #d5385a;
  font-size: 40px;
  margin-top:30px;
  margin-left:90px;
  font-weight: 600;
}

.prettyfunnel{
  max-width: 400px;
}

.descobertaPretty{
  position: absolute;
  top:210px;
  color:#fff;
  font-weight: 600;
  text-align: center;
  left: 325px;

}

.leadsPretty{
  position: absolute;
  top:295px;
  color:#fff;
  font-weight: 600;
  text-align: center;
  left: 325px;

}
.agendaPretty{
  position: absolute;
  top:370px;
  color:#fff;
  font-weight: 600;
  text-align: center;
  left: 325px;
}

.compPretty{
  position: absolute;
  top:435px;
  color:#fff;
  font-weight: 600;
  text-align: center;
  left: 325px;
}

.fechPretty{
  position: absolute;
  top:485px;
  color:#fff;
  font-weight: 600;
  text-align: center;
  left: 325px;
}

.titlePretty{
  position: absolute;
  top:195px;
  font-size: 20px;
  color:#676666;
  font-weight: 600;
  text-align: center;
  left: 35px;
}
.titleLPretty{
  position: absolute;
  top:265px;
  font-size: 20px;
  color:#676666;
  font-weight: 600;
  text-align: center;
  left: 35px;
}

.titleAPretty{
  position: absolute;
  top:335px;
  font-size: 20px;
  color:#676666;
  font-weight: 600;
  text-align: center;
  left: 35px;
}

.titleCPretty{
  position: absolute;
  top:405px;
  font-size: 20px;
  color:#676666;
  font-weight: 600;
  text-align: center;
  left: 35px;
}

.titleFPretty{
  position: absolute;
  top:475px;
  font-size: 20px;
  color:#676666;
  font-weight: 600;
  text-align: center;
  left: 35px;
}

.qshdimg{
  max-width: 130px;
  margin-left: 1330px;
  margin-top:-180px;
}

.selectMonthMetas{
  background-color: rgba(0, 0, 0, .5);
}

.selectMonthMetas .modal-content{
    width: 500px;
    margin-top:350px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #ededed;
}

.selectMonthMetas .modal-content .closeButton{
  background-color: #59202A;
  outline:1px solid #fff;
  border:none !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: 
  0 0 1rem #59202A,
  0 0 0.6rem #59202A,
  0 0 0.5rem #59202A,
  0 0 0rem #59202A,
  inset 0 0 0 #59202A;
}



.selectMonthMetaInvest{
  background-color: rgba(0, 0, 0, .5);
}

.selectMonthMetaInvest .modal-content{
    width: 500px;
    margin-top:350px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #ededed;
}

.selectMonthMetaInvest .modal-content .closeButton{
  background-color: #F6315C;
  outline:1px solid #fff;
  border:none !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: 
  0 0 1rem #F6315C,
  0 0 0.6rem #F6315C,
  0 0 0.5rem #F6315C,
  0 0 0rem #F6315C,
  inset 0 0 0 #F6315C;
}

.btnCalMetas{
  background-color: transparent;
  border: none;
  top:310px;
  position: absolute;
  z-index: 9999;
  right:1100px;
}

.btnCalMetas i{
   color: transparent;
  -webkit-text-stroke-width: 1px;
  font-size: 35px;
  -webkit-text-stroke-color: #fff;
  text-shadow:
  0 0 8px #d5385a,
  0 0 8px #d5385a;
  
}
.btnCalInvest{
  background-color: transparent;
  border: none;
  top:265px;
  position: absolute;
  z-index: 9999;
  left:680px;
}

.btnCalInvest i{
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  font-size: 35px;
  text-shadow:
  0 0 10px #d5385a,
  0 0 22px #d5385a;
}

.btnCalMetaFaturamento{
  background-color: transparent;
  border: none;
  top:265px;
  position: absolute;
  z-index: 9999;
  left:600px;
}

.btnCalMetaFaturamento i{
   color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  font-size: 35px;
  text-shadow:
  0 0 10px #d5385a,
  0 0 22px #d5385a;
  
}
.btnCalAds{
  background-color: transparent;
  border: none;
  top:242px;
  position: absolute;
  z-index: 9999;
  left:570px;
}

.btnCalAds i{
  font-size: 40px;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  font-size: 35px;
  -webkit-text-stroke-color: #fff;
  text-shadow:
  0 0 10px #d5385a,
  0 0 22px #d5385a;
}

.btnCalCPL{
  background-color: transparent;
  border: none;
  top:272px;
  position: absolute;
  z-index: 9999;
  left:800px;
}

.btnCalCPL i{
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  font-size: 35px;
  text-shadow:
  0 0 10px #d5385a,
  0 0 22px #d5385a;
}

.btnCalFunil{
  background-color: transparent;
  border: none;
  top:205px;
  position: absolute;
  z-index: 9999;
  left:240px;
}

.btnCalFunil i{
  font-size: 35px;
  color: #d5385a;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;

}

@media only screen and (min-width:2000px){
  .qshdimg{
    max-width: 130px;
    margin-left: 1385px;
    margin-top:-230px;
  }
  .btnCalCPL{
    top:275px;
    left:840px;
  }
  .btnCalMetas{
    left:760px;
  }
}
@media only screen and (max-width:1700px){
  
.titulofunilG{
  margin-left: -100px;
  margin-top:65px;
  margin-bottom: 0px;
}
.tabelaGoogle table{
  margin-left: -100px;
  width: 280px;
}

.tablefb table{
  width: 400px;
  margin-left: -110px;
}
.titulofunilT{
  margin-left: -110px;

}
.tableTicket table{
  width: 600px;
  margin-left: -110px;
}

.titulofunil{
  margin-left: -110px;
}
.qshdimg{
  max-width: 130px;
  margin-left: 1150px;
  margin-top:-230px;
}
.btnCalMetas{
  left:700px;
}
.btnCalMetaFaturamento{
  left:500px;
}
.btnCalInvest{
  left:560px;
}
.btnCalCPL{
  left:700px;
}
.btnCalAds{
  left:450px;
}
.btnCalFunil{
  background-color: transparent;
  border: none;
  top:330px;
  position: absolute;
  z-index: 9999;
  left:260px;
}
}

@media only screen and (max-width:1600px){
  
  .titulofunilG{
    margin-left: -60px;
    margin-top:65px;
    margin-bottom: 0px;
  }
  .tabelaGoogle table{
    margin-left: -60px;
    width: 220px;
  }
  
  .tablefb table{
    width: 400px;
    margin-left: -70px;
  }
  .titulofunilT{
    margin-left: -70px;
  
  }
  .tableTicket table{
    width: 500px;
    margin-left: -70px;
  }
  
  .titulofunil{
    margin-left: -70px;
  }

  .prettyfunnel{
    margin-top:-10px;
    max-width: 350px;
  }
  .descobertaPretty{
    top:190px;
    left: 295px;
  }
  
  .leadsPretty{
    top:260px;
    left: 295px;
  }

  .agendaPretty{
    top:320px;
    left: 295px;
  }
  
  .compPretty{
    top:380px;
    left: 295px;
  }
  
  .fechPretty{
    top:425px;
    left: 295px;
  }
  
  .titlePretty{
    top:150px;
  }
  .titleLPretty{
    top:230px;
  }
  
  .titleAPretty{
    top:300px;
  }
  
  .titleCPretty{
    top:370px;
  }
  
  .titleFPretty{

    top:445px;
  }
  .tablefb table{
    width: 380px;
  }

  .titulofunilT{
    margin-top:-100px;
  
  }

  .qshdimg{
    max-width: 130px;
    margin-left: 1060px;
    margin-top:-230px;
  }
  .metaFaturamentoTitle{
    font-size: 40px;
  }
  .btnCalFunil{
    background-color: transparent;
    border: none;
    top:230px;
    position: absolute;
    z-index: 9999;
    left:260px;
  }
  .btnCalMetas{
    left:630px;
  }
  .btnCalMetaFaturamento{
    left:520px;
    top:278px;
  }
  .btnCalInvest{
    top:278px;
    left:560px;
  }
  .btnCalCPL{
    top:288px;
    left:650px;
  }
  .btnCalAds{
    left:400px;
  }
}

@media only screen and (max-width:1560px){
  .metaFaturamentoTitle{
    font-size: 35px;
  }
  .titulofunilG{
    margin-left: -40px;
    margin-top:35px;
    font-size: 13px;
    margin-bottom: 0px;
  }
  .tabelaGoogle table{
    margin-left: -40px;
    width: 220px;
  }
  
  .tablefb table{
    width: 400px;
    margin-left: -70px;
  }
  .titulofunilT{
    margin-left: -70px;
    margin-top:-130px;
    font-size: 13px;
  
  }
  .tableTicket table{
    width: 500px;
    margin-left: -70px;
  }
  
  .titulofunil{
    margin-left: -70px;
    font-size: 13px;
    margin-top:35px;
  }
  .qshdimg{
    max-width: 130px;
    margin-left: 1030px;
    margin-top:-230px;
  }
  .btnCalAds{
    left:380px;
  }
  .btnCalMetas{
    left:620px;
  }
  .btnCalMetaFaturamento{
    left:520px;
    top:256px;
  }
  .btnCalInvest{
    top:256px;
    left:560px;
  }
  .btnCalCPL{
    top:265px;
    left:650px;
  }
  .btnCalFunil{
    top:205px;
    left:340px;
  }
}

@media only screen and (max-width:1440px){
  .prettyfunnel{
    margin-top:-10px;
    max-width: 300px;
  }
  .descobertaPretty{
    top:170px;
    left: 275px;
  }
  
  .leadsPretty{
    top:230px;
    left: 275px;
  }

  .agendaPretty{
    top:290px;
    left: 275px;
  }
  
  .compPretty{
    top:340px;
    left: 275px;
  }
  
  .fechPretty{
    top:375px;
    left: 275px;
  }
  
  .titlePretty{
    top:150px;
    font-size: 16px;
  }
  .titleLPretty{
    top:210px;
    font-size: 16px;
  }
  
  .titleAPretty{
    top:280px;
    font-size: 16px;
  }
  
  .titleCPretty{
    top:340px;
    font-size: 16px;
  }
  
  .titleFPretty{
    font-size: 16px;
    top:395px;
  }

  .titulofunilG{
    margin-left: -20px;
    margin-top:35px;
    font-size: 13px;
    margin-bottom: 0px;
  }
  .tabelaGoogle table{
    margin-left: -20px;
    width: 190px;
  }
  
  .tablefb table{
    width: 380px;
    margin-left: -70px;
  }
  .titulofunilT{
    margin-left: -70px;
    margin-top:-60px;
    font-size: 13px;
  
  }
  .tableTicket table{
    width: 500px;
    margin-left: -70px;
  }
  
  .titulofunil{
    margin-left: -70px;
    font-size: 13px;
    margin-top:50px;
  }
  .qshdimg{
    max-width: 110px;
    margin-left: 990px;
    margin-top:-230px;
  }  
  .btnCalAds{
    left:340px;
  }
  .btnCalMetas{
    left:580px;
  }
  .btnCalMetaFaturamento{
    left:470px;
    top:256px;
  }
  .btnCalInvest{
    top:256px;
    left:520px;
  }
  .btnCalCPL{
    top:265px;
    left:600px;
  }
  .btnCalFunil{
    top:205px;
    left:310px;
  }
}

@media only screen and (max-width:1366px){
  .projection{
    font-size: 60px;
    margin-top:220px;
  }
  .metaTitle{
    font-size: 50px;
    margin-top:-10px;
  }
  .tableTicket table{
    width: 470px;
    margin-left: -70px;
  }
  
  .qshdimg{
    max-width: 100px;
    margin-left: 930px;
    margin-top:-230px;
  }
  .btnCalAds{
    left:300px;
  }
  .btnCalMetas{
    left:520px;
    top:248px;
  }
  .btnCalMetaFaturamento{
    left:395px;
    top:268px;
  }
  .btnCalInvest{
    top:269px;
    left:440px;
  }
  .btnCalCPL{
    top:265px;
    left:525px;
  }
  .btnCalFunil{
    top:265px;
    left:220px;
  }
}

@media only screen and (max-width:1280px){

  .metaBtn{
    height: 200px;
    width: 200px;
    margin-left:30px;
  }
  .titulofunilT{
    margin-left: -70px;
    margin-top:-50px;
    font-size: 13px;
  
  }
  .qshdimg{
    max-width: 100px;
    margin-left: 960px;
    margin-top:-230px;
  }
  .btnCalAds{
    left:260px;
  }
  .btnCalFunil{
    left:180px;
  }
  .btnCalMetas{
    left:480px;
    top:248px;
  }
}
