.negado{
  color:#414141;
  text-align: center;
  font-size: 20px;
  margin-top:100px;
  margin-left: 250px;
  font-weight: 600;
}

.negadoGist{
  color:#414141;
  text-align: center;
  font-size: 20px;
  margin-top:100px;
  margin-left: -30px;
  font-weight: 600;
}