.dadosTitle{
  font-size: 65px;
  margin-top:100px;
  margin-bottom: 0px;
  font-weight: 800;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  text-shadow:
  0 0 10px #d5385a,
  0 0 22px #d5385a;
}

.dadosGTitle{
  font-size: 65px;
  margin-top:360px;
  margin-bottom: 0px;
  font-weight: 800;
  color: white;

}

.buttonDados{
  background-color: #414141;
  color:#fff;
  border:none !important;
  outline: 1px solid #fff;
  width: 400px;
  box-shadow: 
  0 0 1.2rem #d5385a,
  0 0 0.8rem #d5385a,
  0 0 0.6rem #9D2727,
  0 0 0.5rem #9D2727,
  0 0 0rem #9D2727,
  inset 0 0 0 #9D2727;
  height: 40px;
  font-size: 20px;
  border-radius: 10px;
}

.dadosList ul li{
  list-style: none !important;
  margin-top:30px;
}
.titleContrato{
  font-size: 55px;
  margin-top:190px;
  margin-bottom: 0px;
  font-weight: 800;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  text-shadow:
  0 0 10px #d5385a,
  0 0 22px #d5385a;
}

.titleServicos{
  font-size: 55px;
  margin-top:90px;
  margin-bottom: 0px;
  font-weight: 800;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  text-shadow:
  0 0 10px #d5385a,
  0 0 22px #d5385a;
}

.titleHistorico{
  font-size: 55px;
  margin-top:130px;
  margin-bottom: 0px;
  font-weight: 800;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  text-shadow:
  0 0 10px #d5385a,
  0 0 22px #d5385a;
}

.btnContrato{  background-color: #414141;
  color:#fff;
  border:none !important;
  outline: 1px solid #fff;
  width: 400px;
  margin-top:10px;
  box-shadow: 
  0 0 1.2rem #d5385a,
  0 0 0.8rem #d5385a,
  0 0 0.6rem #9D2727,
  0 0 0.5rem #9D2727,
  0 0 0rem #9D2727,
  inset 0 0 0 #9D2727;
  height: 60px;
  font-size: 20px;
  border-radius: 10px;
}

.btnContrato a{
  color: #fff;
  text-decoration: none !important;
}
.btnContrato i{
 padding-right: 10px;
}

.btnHistorico{
  background-color: #414141;
  color:#fff;
  border:none !important;
  outline: 1px solid #fff;
  width: 400px;
  margin-top:30px;
  box-shadow: 
  0 0 1.2rem #d5385a,
  0 0 0.8rem #d5385a,
  0 0 0.6rem #9D2727,
  0 0 0.5rem #9D2727,
  0 0 0rem #9D2727,
  inset 0 0 0 #9D2727;
  height: 60px;
  font-size: 20px;
  border-radius: 10px;
}

.btnHistorico a{
  color: #fff;
  text-decoration: none !important;
}
.btnHistorico i{
 padding-right: 10px;
}
.boxServicos{
  height: 400px;
  width: 800px;
  background-color: #414141;
  border-radius: 10px;
  box-shadow: 
  0 0 1.2rem #d5385a,
  0 0 0.8rem #d5385a,
  0 0 0.6rem #9D2727,
  0 0 0.5rem #9D2727,
  0 0 0rem #9D2727,
  inset 0 0 0 #9D2727;
  outline: 1px solid #fff;
  margin-top:10px;
}


.boxServicos ul li{
  list-style: none;
  color:#fff;
  font-family: 'TT Norms' !important;
  font-size: 20px;
  margin-top:10px;
}

.boxCheck{
  width: 35px;
  height: 35px;
  text-align: center;
  vertical-align: middle;
  text-shadow: 
  0 0 10px #d5385a,
  0 0 22px #d5385a;
  outline: 3px solid #d5385a;
  border-radius: 5px;
  margin-top: 10px;
  box-shadow: 
  0 0 0.8rem #d5385a,
  0 0 0.6rem #9D2727,
  0 0 0.5rem #9D2727,
  0 0 0rem #9D2727,
  inset 0 0 0 #9D2727;
  font-weight: 800 !important;
  font-size: 30px !important;
  margin-right: 10px;
}



@media only screen and (max-width:1600px){
.dadosTitle{
  margin-top:30px;
}
.dadosGTitle{
  margin-top:280px;
}
.titleContrato{
  margin-top:135px;
}
.titleNotas{
  
  margin-top:90px !important;
}
.titleHistorico{
  
  margin-top:90px !important;
}


.titleServicos{
  margin-top:20px;
}


}

@media only screen and (max-width:1550px){
  
.dadosGTitle{
  margin-top:260px;
}
.titleNotas{
  
  margin-top:70px !important;
}
.titleHistorico{
  
  margin-top:90px !important;
}
.titleServicos{
  margin-top:10px;
}

}

@media only screen and (max-width:1370px){
  .dadosTitle{
    margin-top:15px;
  }
  .titleContrato{
    margin-top:95px;
  }
  .titleNotas{
  
    margin-top:30px !important;
  }
  .titleHistorico{
  
    margin-top:30px !important;
  }
  .titleServicos{
    margin-top:0px;
    font-size: 45px;
  }
    
  .boxServicos{
    height: 300px;
    width: 800px;
  }
  
  .boxServicos ul li{
    font-size: 18px;
  }
  
  .boxCheck{
    width: 25px;
    height: 25px;
    margin-top: 5px;
    font-size: 25px !important;
  }
  
}

@media only screen and (max-width:1280px){
  .dadosTitle{
    margin-top:0px;
  }
  .dadosGTitle{
    margin-top:230px;
  }
  .titleContrato{
    margin-top:75px;
  }
  .titleNotas{
  
    margin-top:10px !important;
  }
  
  .titleHistorico{
  
    margin-top:10px !important;
  }
  
  .boxCheck{
    width: 25px;
    height: 25px;
    margin-top: 5px;
  }
  

}
