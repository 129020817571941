#contentDash{
  width: 100% !important;
  height: 100vh;
  max-height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  //background-image: url("../img/bg2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: #202127;
}
.line-cal1{
  top: 5rem !important;
  left: 54rem !important;
  height: 6rem;
  width: 55vw;
  transform: rotate(-90deg);
  opacity: 0.2 !important;
}
.line-doc1{
  top: 5rem !important;
  left: 44rem;
  height: 6rem;
  width: 55vw;
  transform: rotate(-90deg);
  opacity: 0.2 !important;
}

.line-doc2{
  top: 20rem;
  left: 0rem;
  height: 6rem;
  width: 55vw;
  opacity: 0.2 !important;
}

.line-doc3{
  top: 45rem;
  left: 40rem;
  height: 6rem;
  width: 55vw;
  opacity: 0.2 !important;
}
.line-1{
  top: 15rem;
  left: 40rem;
  height: 6rem;
  width: 55vw;
  transform: rotate(-90deg);
  opacity: 0.2 !important;
}
.line-V{
  top: 15rem;
  left: 48rem;
  height: 6rem;
  width: 55vw;
  transform: rotate(-90deg);
  opacity: 0.2 !important;
}
.line-2{
  top: 20rem;
  left: 0rem;
  height: 6rem;
  width: 55vw;
  opacity: 0.2 !important;
}
.line-3{
  top: 45rem;
  left: 40rem;
  height: 6rem;
  width: 55vw;
  opacity: 0.2 !important;
}
.changePassword .modal-content{
  width: 250px;
  margin-left:730px;
  margin-top:90px;
}
.changePassword .modal-body{
  margin-left:20px;

}


.changePassword .modal-content .closeButton{
  width: 250px;
  border: none !important;
  background-color: #7A3941;
}

.modal-backdrop {
  /* bug fix - no overlay */    
  display: none;    
}

.perfilUpdate{
  background-color: #050505 !important;
  border:none !important;
  opacity: 1;
  margin-top:10px;
  width: 180px;
}

.inputPass{
  width: 180px;
  outline: none !important;
  border: 1px solid gray;
  border-radius: 5px;
  margin-top:10px;
}

.cameraButton{
  background-color: #7A3941;
  border:none !important;
  margin-left: 60px;
}
.editPassword{
  border: none;
  border-radius: 50%;
  background-color:#272727 ;
  margin-top:-12px;
  position:absolute;
  display: block;
  margin-left: 110px;
  top:80px;
}

.editPassword i{
  font-size: 13px;
}

.lastAccess{
  font-size: 11px;
}

.suporte .modal-content{
    width: 400px !important;
    background-color: #434242;
    color:#fff;
    margin-top: 250px;
}

.suporte .modal-content .closeButton{
  background-color: #434242;
  border:none !important;
  box-shadow: none !important;
}

.inptSuporte{
  border-radius: 5px;
  width: 350px;
  box-shadow: 
  0 0 12px #d5385a,
  0 0 22px #d5385a;
  outline: none;
  border: none;
  margin-top:5px;
  margin-bottom:10px;
  height: 40px;
}

.inptMessage{
  border-radius: 5px;
  width: 350px;
  box-shadow: 
  0 0 12px #d5385a,
  0 0 22px #d5385a;
  outline: none;
  border: none;
  margin-top:5px;
  margin-bottom:20px;
  height: 90px;
}

.sendSup{
  width: 350px;
  height: 40px;
  border:none !important;
  background-color: #d5385a;
  border-radius: 5px;
  color:#fff;
  font-weight: 500;
}

.supp{
  margin-left: 10px;
}

.supButton{
 position:fixed;
 bottom:15px;
 right: 10px;
 background-color: transparent;
 border:none !important;
 outline:none !important;
}

.callSup{
  font-size: 30px;
  text-align: center;
  margin-bottom: 10px;
}
.selectConta{
  width: 200px;
}

.selectContaAdm{
  width: 130px;
}

.botao-empresaAdmin img{
  width: 130px;
  border-radius: .5rem;
}

.selecaoContasAdm ul{
  width: 80vw;
  max-height: 75vh;
  overflow-y: auto;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: .8rem 0 0 .8rem;
}


.selecaoContasAdm ul::-webkit-scrollbar {
  background-color: #ededed !important;
  width: 10px;
  }
  
  .selecaoContasAdm ul::-webkit-scrollbar-thumb {
  background-color: #EF232C;
  border-radius: 0;
  height: 5px;
  }
  .selecaoContasAdm ul::-webkit-scrollbar-track {
  background-color: #c8c8c8;
  border: gray !important;
  height: 80%;
  border-radius: 25px;  
  }
  
  .selecaoContasAdm ul::-webkit-scrollbar-track-piece{
  background-color: #c8c8c8 !important;
  }

.selecaoContasAdm ul li{
  display: inline-block !important;
  padding: 30px;
}

.selecaoContasAdm{
  margin-top:20px;
}




.selecaoContas{
  margin-top:150px;
}
.selecaoContas ul li{
  display: inline-block !important;
  padding: 10px;
  line-height: 100%;
}
.empresa{
  margin-top:00px;
  color:#fff;
}

.videoMenChung{
  position:absolute;
 bottom:0px !important; 
}

.botao-empresa{
width: 240px;
height: 240px;
  background-color: #ededed;
  border-radius: .5rem;
  border:none !important;
  color:#fff;
}


.botao-empresaAdmin{
  width: 141px;
  height: 134px;
  background-color: #ededed;
  border-radius: .5rem;
  border:none !important;
  color:#fff;
  }

.text-empresa{
  width: 140px;
}

.contadorr{ 
  color: transparent;
}

/*.tituloselecao{*/
/*  font-size: 60px;*/
/*  margin-top:30px;*/
/*  margin-bottom: 0px;*/
/*  font-weight: 800;*/
/*  color: transparent;*/
/*  -webkit-text-stroke-width: 1px;*/
/*  -webkit-text-stroke-color: #fff;*/
/*  text-shadow:*/
/*  0 0 10px #d5385a,*/
/*  0 0 22px #d5385a;*/
/*}*/
.tituloselecao{
  padding-top: 1.7rem;
  font-size: 40px;
  font-weight: 800;
  color: #EF232C;
}


@media only screen and (max-width:1700px){
  .supButton{
    position:fixed;
    bottom:35px;
    right: 70px;
    background-color: transparent;
    border:none !important;
    outline:none !important;
   }

   .line-1{
    top: 15rem;
    left: 30rem;
    height: 6rem;
    width: 55vw;
    transform: rotate(-90deg);
    opacity: 0.2 !important;
  }
  .line-2{
    top: 20rem;
    left: 0rem;
    height: 6rem;
    width: 55vw;
    opacity: 0.2 !important;
  }
  .line-3{
    top: 55rem;
    left: 20rem;
    height: 6rem;
    width: 55vw;
    opacity: 0.2 !important;
  }
  
.suporte .modal-content{
  margin-left: 70px;
}

}
@media only screen and (max-width:1600px){

   .line-1{
    top: 15rem;
    left: 38rem;
    height: 6rem;
    width: 55vw;
    transform: rotate(-90deg);
    opacity: 0.2 !important;
  }
  .line-2{
    top: 20rem;
    left: 0rem;
    height: 6rem;
    width: 55vw;
    opacity: 0.2 !important;
  }
  .line-3{
    top: 35rem;
    left: 20rem;
    height: 6rem;
    width: 55vw;
    opacity: 0.2 !important;
  }
  .supButton{
    position:fixed;
    bottom:10px;
    right: 30px !important;
    background-color: transparent;
    border:none !important;
    outline:none !important;
   
}
}
@media only screen and (max-width:1370px){
  
.selecaoContas{
  margin-top:70px;
}
.suporte .modal-content{
  margin-top: 70px;
}
.supButton{
  position:fixed;
  bottom:10px;
  right: 30px !important;
  background-color: transparent;
  border:none !important;
  outline:none !important;
 
}
}
@media only screen and (max-width:1280px){
  
  .editPassword{
    border: none;
    border-radius: 50%;
    background-color:#272727 ;
    margin-top:5px;
    position:absolute;
    display: block;
    margin-left: 65px;
    top:80px;
  }
  
  .editPassword i{
    font-size: 13px;
  }
  }

