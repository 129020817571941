.btnPrintFunil{
  background-color: transparent;
  border: none;
  top:205px;
  position: absolute;
  z-index: 9999;
  left:285px;
}

.btnPrintFunil i{
  font-size: 35px;
  color: #3100e2;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;

}

#bgFunile{
  background-image: url('../img/fundoHorizontal.png');
  background-repeat: no-repeat;
  background-size:1050px 725px !important; 
  background-position: top;
  height: 725px;
  overflow-y: hidden;
  overflow-x: hidden;
}


.projetadoPrint{
  margin-top:10px;
  margin-left: 530px;
  width: 500px;
}

#funilPrint{
  margin-left:220px;
  margin-top:80px !important; 
  padding-top:20px !important;
  width: 300px;
}

.prettyfunnelPrint{
  max-width: 300px;
}

.titlePrettyPrint{
  position: absolute;
  top:165px !important;
  font-size: 15px;
  color:#676666;
  font-weight: 600;
  text-align: center;
  left: 170px;
}
.titleLPrettyPrint{
  position: absolute;
  top:235px !important;
  font-size: 15px;
  color:#676666;
  font-weight: 600;
  text-align: center;
  left:170px;
}

.titleAPrettyPrint{
  position: absolute;
  top:295px !important;
  font-size: 15px;
  color:#676666;
  font-weight: 600;
  text-align: center;
  left: 170px;
}

.titleCPrettyPrint{
  position: absolute;
  top:345px !important;
  font-size: 15px;
  color:#676666;
  font-weight: 600;
  text-align: center;
  left: 170px;
}

.titleFPrettyPrint{
  position: absolute;
  top:395px !important;
  font-size: 15px;
  color:#676666;
  font-weight: 600;
  text-align: center;
  left: 170px;
}


.tituloFunilPrint{
  font-size: 30px;
  margin-top:20px;
  margin-bottom: -50px;
  font-weight: 800;
  color: #d5385a;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
}



.descobertaPrettyPrint{
  position: absolute;
  top:190px !important;
  color:#fff;
  font-weight: 600;
  text-align: center;
  left: 360px;

}

.leadsPrettyPrint{
  position: absolute;
  top:250px !important;
  color:#fff;
  font-weight: 600;
  text-align: center;
  left: 360px;

}
.agendaPrettyPrint{
  position: absolute;
  top:310px !important;
  color:#fff;
  font-weight: 600;
  text-align: center;
  left: 360px;
}

.compPrettyPrint{
  position: absolute;
  top: 360px !important; 
  color:#fff;
  font-weight: 600;
  text-align: center;
  left: 360px;
}

.fechPrettyPrint{
  position: absolute;
  top:395px !important;
  color:#fff;
  font-weight: 600;
  text-align: center;
  left: 355px;
}


.ticketPrint{
  color:#d5385a;
  font-size: 11px;
  width: 500px !important;
  font-weight: 500;
  }
  
  .tableTicketPrint table tr{
    width: 500px;
  }
  .tabelaGooglePrint{
    padding-top:0px !important;
    margin-top:40px;
    width: 200px;
    margin-left: 850px;
  }
  
  
    
  .tablefbPrint table{
    width: 280px;
    margin-top:20px;
    margin-left: 550px;
  }
  
  .tablefbPrint tr{
    min-width: 160px !important;
  }
  
  .titulofunilPrint{
    margin-left: 550px;
    margin-top:-350px;
    color:#d5385a;
    font-size: 11px;
    font-weight: 500;
  }
  
   .googlePrint{
    margin-top:20px;
    margin-left:20px;
    width: 130px;
   }
  
   .titulofunilGPrint{
    margin-top:-390px;
    color:#d5385a;
    font-weight: 500;
    font-size: 11px;
   }
   