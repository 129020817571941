#contentPlannerPrint{
  background-image: url('../img/fundoHorizontal.png');
  background-repeat: no-repeat;
  background-size:1050px 725px !important; 
  background-position: top;
  height: 1725px;
  overflow-x: hidden;
}

#contentPlannerPrintBottom{
  background-image: url('../img/fundoHorizontal.png');
  background-repeat: no-repeat;
  background-size:1050px 725px !important; 
  background-position: top;
  height: 1725px;
  margin-top:-930px;
  overflow-x: hidden;
}

@page {
  size: landscape;
}
/* Weekdays (Mon-Sun) */
.weekdaysPrint  {
  margin-top: 0px;
  padding: 10px 30px 0px 30px;
  background-color: #F6315C;
  margin-left: 160px;
  width: 850px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: 
  0 0 1rem #F6315C,
  0 0 0.6rem #F6315C,
  0 0 0.5rem #F6315C,
  0 0 0rem #F6315C,
  inset 0 0 0 #F6315C;
  outline: 1px solid #fff;
}

.weekdaysPrint ul{
  width: 850px !important;
  background-color:#414141;
  margin-left: -30px;
  height: 40px;
  padding-left: 0px;
  padding-top: 10px;
  margin-bottom: 5px;
}
.weekdaysPrint li {
  display: inline-block;
  width: 14.2%;
  margin-bottom: 0px;
  color: #fff;
  text-align: center;
  background-color:#414141;
  list-style: none !important;
  text-decoration: none !important;
}


.Cal-endarPrint{
  height: 480px;
  width:950px !important;
  margin-left: 60px;
  overflow-x: hidden;
  overflow-y: hidden;
}

/* Days (1-31) */
.daysPrint {
  margin: 0;
  margin-left: 100px;
  background-color: #ededed;
  width: 900px !important;
  padding:5px;
}

.daysPrint li {
  list-style-type: none;
  display: inline-block;
  text-align: center;
  font-size:12px;
  color: #777;
}
.bodyCalPrint{
  background-color: #ededed;
  margin-right: 5px;
  width: 118px;
  overflow-y: hidden;
  text-overflow: ellipsis;
  height:230px;
}

.legendPrintPlan{
  text-align: justify;
  text-overflow: ellipsis;
  font-size: 11px;
  color:#000;
}


.bodyCaloffPrint{
  background-color: #414141;
  margin-right: 5px;
  width: 118px;
  opacity: 0.5;
  height:230px !important;
  margin-bottom: 0px;
  margin-top:-20px;
}

.dayPrint{
  background-color: #414141;
  color: #fff;
  width: 118px;
  margin-top: -0px;

}

li.dayPrint{ 
  margin-top:0px !important;
}

.imagemPrint{
  max-width: 70px;
  height: 70px;
  padding-top: 10px;
  object-fit: cover;
}

.modalTeste .modal-content{
  margin-left: -700px;
  width: 2000px;
}
.titleCalPrint{ 
   margin-top:20px;
  font-size: 20px;
  font-weight: 800;
  margin-left: 140px !important;
  color: #F6315C;
  text-shadow:
  0 0 10px #d5385a,
  0 0 22px #d5385a,
  0 0 32px #d5385a;
}

.boxPrint{
  background-color: #ededed;
  margin-right: 5px;
  width: 180px !important;
  height:270px !important;
  max-height: 270px !important;
  padding-top:20px;
  height: 100%;
  display: table-cell;
  font-size: 14px;
  overflow-y:hidden;
}

.subtitleCalPrint{
  font-size: 20px;
  text-align: center;
}

.tituloPrintPlanner{
  font-weight: 500;
  color:#F6315C;
  font-size: 30px;
margin-left: 80px;
}

.nomeNegTitulo{
  color:#F6315C;
  font-size: 20px;
margin-left: 80px;
}