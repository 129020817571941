
@import "./lbd/transitions";

.logout_bt{
  border-color: #3472f700;
  color: black;
  margin: 0 0 !important;

  i{
    color: #b10000;
    font-size: 22px;
    padding: 0 0 !important;
  }
}

.noticacoes i{
  font-size:40px;
  color:#e85628;
}

.noticacoes i:hover{
  color:#fff;
}


.noticacoesmenu i{
  font-size:40px;
  color:#e85628;
  position: absolute;
  margin-left:520px;
  margin-top:-55px;
}

.noticacoesmenu i:hover{
  color:#8c8c8c;
}

.extrato i:hover{
  color:whitesmoke;
}

.logout_bt:hover{
  border-color: #3472f700;
}

.logout_user{
  background-color:transparent;
  outline:none !important;
  border: none;
  width: 100px;
  color: #d5375a;
  font-size: 19px !important;
  text-decoration: none;
}

.logout_user:hover{
  background-color:transparent !important;
  outline:none !important;
  border: none !important;
  color: #fff;
  text-decoration: none;
}

.logout_user:active{
  background-color:transparent !important;
  outline:none !important;
  border: none !important;
  color: #d5375a;
}

.logout_user:focus{
  background-color:transparent !important;
  outline:none !important;
  border: none !important;
  color: #d5375a;
}

.logout_user a{
  font-family: "TT Norms";
  font-size: 19px;
  color: #d5375a;
  font-weight: 500;
  text-decoration: none;
}

.logout_user a:hover{
  color:#fff;
}

.logout_user i{
  padding-left:10px;
  color: #008bb2;
}

.NavbarMenu{
  background-color: #434242;
  opacity: 100%;
}

.profilemenu{
  height: 65px !important;
  width: 65px !important;
  border-radius:65px;
  padding: 0;
  border: 2px solid #EF232C;
}

.profilebutton{
  background-color: transparent;
  height: 70px !important;
  border:none !important;
  box-shadow: none !important;  
  margin-left: 0;
}


//#dropdown-basic:hover{
//  background-color: transparent !important;
//  border: 0 !important;
//  box-shadow: none !important;
//}
//#dropdown-basic:active{
//  background-color: transparent !important;
//  border: 0 !important;
//  box-shadow: none !important;
//}

i.fa.fa-user::before{
  color: #7A3941;
  padding-right: 10px;
  font-size: 19px;
}
i.fa.fa-envelope::before{
  color: #7A3941;
  padding-right: 10px;
  font-size: 19px;
}
i.fa.fa-question::before{
  color: #7A3941;
  padding-right: 10px;
  font-size: 19px;
}
i.fa.fa-power-off::before{
  color: #7A3941;
  padding-right: 10px;
  font-size: 19px;
}

.editSenha{
  color:#fff;
  font-weight: 600;
  background-color: #7A3941;
  border: none !important;
  padding:5px;
  margin-left:10px;
  font-size: 18px;
  border-radius: 10px;
  letter-spacing: 3px;
}

.color-white{
  color:#fff;
}

.nav-text-link{
  color:#fff;
  border:none !important;
  background-color: #414141;
  font-size: 17px;
}

.nav-text-link:hover{
  color:#d5375a;
  font-size: 17px;
  text-decoration: none !important;
  border-bottom: 2px solid #d5375a;
}

.navbar {
  width: auto;
  background-color: rgba(0,0,0,0.75);
  padding: 0px 0px;
  .navbar-brand img {
    width: 140px;
    margin-left: -150px;
  }

  
 
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle{
  background-color: #FFFFFF72 !important;
  font-weight: 600;
  color: black;
  border: 2px #ad0c00 !important;
  box-shadow: 0 0 10px #ad0c00 !important;
}

.atualNegocio{
  color: #fff;
  text-align: center;
  border: 2px solid #EF232C;
  border-radius: .5rem;
  padding:5px;
  margin-left: 10px;
  width: 13rem;
}

.widthThing{
  width: 200px !important;
}

#navbarContent{
  margin-left: 0px;
  display:flex;
}

.important{
  padding-left:30px !important;
  width: max-content;
}


.navbar-toggler{
  margin-left:10rem;
}

.dropdown-menu{
  background-color: #d2cdcc;
  margin-left:-50px;
}

.dropdown-item{  
  border-bottom: 1px solid #727073;
}

.dropdown-item:hover{
  background-color: #d5375a;
  color:#fff;
}

@media only screen and (max-width:1500px){
  .navbar {
    width: auto;
    background-color: rgba(0,0,0,0.75);
    padding: 0px 0px;
    .navbar-brand img {
      width: 100px;
      margin-left: -100px;
    }
  
  }

  .nav-text-link{
    color:#fff;
    font-size: 16px;
  }
  
  .nav-text-link:hover{
    color:#d5375a;
    font-size: 16px;
    text-decoration: none !important;
    border-bottom: 2px solid #d5375a;
    font-weight: 600;
  }
  .profilemenu{
    height: 55px !important;
    width: 55px !important;
  }
}

@media only screen and (max-width:1300px){
  .navbar {
    width: auto;
    background-color: rgba(0,0,0,0.75);
    padding: 0px 0px;
    .navbar-brand img {
      width: 80px;
      margin-left: -50px;
    }
  
  }
  .nav-text-link{
    color:#fff;
    font-size: 15px;
  }
  
  .nav-text-link:hover{
    color:#d5375a;
    font-size: 15px;
    text-decoration: none !important;
    border-bottom: 2px solid #d5375a;
    font-weight: 600;
  }
  
.profilebutton{
  margin-left: -10px;
}

}