.formProjecoes{
  margin-left: 360px;
  border: 1px solid gray !important;
  width: 1500px;
  padding:50px;
  margin-top:20px;
  max-height: 800px;
  overflow-y:auto ;
  overflow-x: hidden;
}

.alertFields{
  width: 800px;
  height: 200px;
  position:absolute;
  top:10px;
  left:650px;
  background-color: #414141;
  color:#d5375a;
  z-index: 9999;
}
.alertFields a{
  color: #fff !important;
  font-weight: 500;
}
.btnOk{
  background-color: #414141;
  color:#F6315C;
  border: solid 1px #F6315C;
}

.btnOk:hover{
  background-color: #F6315C;
  color:#414141;
  border: solid 1px #F6315C;
}
.red{
  color:#F6315C;
}
.tituloProjecoes{
  color: #d5375a;
  font-size: 38px;
  margin-left: 900px;
  font-weight: 600;
  margin-top:30px;
}

.formProjecoes label{
  font-size: 20px;
  padding: 10px;
  width: 500px;
}

.formProjecoes::-webkit-scrollbar {
  background-color: #ededed !important;
  width: 10px;
  }
  
  .formProjecoes::-webkit-scrollbar-thumb {
  background-color: #d5375a;
  border-radius: 25px;
  height: 5px;
  }
  .formProjecoes::-webkit-scrollbar-track {
  background-color: #c8c8c8;
  border: gray !important;
  height: 80%;
  border-radius: 25px;  
  }
  
  .formProjecoes::-webkit-scrollbar-track-piece{
  background-color: #c8c8c8 !important;
  }

  .roseColored i{
    color:#d5375a;
  }

  .inputProjecoes{
    width: 500px;
    height: 40px;
    margin-left:100px;
    background-color: #c8c8c8;
    box-shadow: none !important;
    border: solid 1px gray;
    margin-bottom:5px;
  }
  .inputProjecoes2{
    width: 500px;
    height: 40px;
    margin-left:200px;
    background-color: #c8c8c8;
    box-shadow: none !important;
    border: solid 1px gray;
    margin-bottom:5px;
  }
  .inputProjecoesmini{
    width: 250px;
    height: 40px;
    margin-left:0px;
    background-color: #c8c8c8;
    box-shadow: none !important;
    border: solid 1px gray;
    margin-bottom:5px;
  }

  .selectionProjecao select{
    width: 500px;
    height: 40px;
    margin-left:250px;
    background-color: #c8c8c8;
    box-shadow: none !important;
    border: solid 1px gray;
  }

  .selectionProjecaoMes select{
    width: 500px;
    height: 40px;
    margin-left:100px;
    background-color: #c8c8c8;
    box-shadow: none !important;
    border: solid 1px gray;
  }
  .btnProjecoes{
    margin-left:605px;
    width: 160px;
    background-color: #d5375a;
    border: none !important;
    border-radius: 5px;
    height:40px;
    margin-top:30px;
  }

  .avisoProjecoes{
    width:800px;
    text-align: center;
    font-size: 16px;
    margin-bottom: 20px !important;
  }
  .table2{
    width: 800px;
  }

  /* The container */
.containerAnalytics {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.containerAnalytics input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.barchart svg{
 margin-top:70px;
}

input.editProjecoes{
  margin-top:7px;
  border: none !important;
  border-radius: 3px;
  height: 35px;
}

.inputProjecoes3{
  background-color: #c8c8c8 !important;
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid gray;
  width: 60px;
  text-align: center;
}

.inputProjecoes4{
  background-color: #fff !important;
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid #ededed;
  width: 60px;
  text-align: center;
  
}
.inputProjecoes5{
  background-color: #fff !important;
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid #ededed;
  width: 60px;
  margin-left: 10px;
  text-align: center;
  
}
.form-control {
  font-family: "FontAwesome"
}

.btnEditPen{
  background-color:#c8c8c8;
  border: none !important;
  color:#F6315C;
  font-family: "FontAwesome";
  width: 30px;
}

.btnEditPen:focus{
  background-color:#c8c8c8;
  border: none !important;
  color:#fff;
  font-family: "FontAwesome";
  width: 30px;
  outline: none !important;
}

.btnEditPen:focus::placeholder{
  color:#fff;
  font-size: 18px;
}

.btnEditPen::placeholder{
  color:#F6315C;
}

.fa-pencil:before{
  color: #F6315C ;
  font-size: 18px;
}

.fa-pencil:after{
  color: #fff ;
}

.allinfo {
  font-size: 30px;
  color:#414141;
  margin-bottom:30px;
  margin-top:30px;
}

.allinfo2{
  margin-bottom:20px;
  margin-top:20px;
  font-size: 25px;
}

.datalabel{
font-size: 16px !important;
vertical-align: middle !important;
padding:0;
}


.datalabel2{
  font-size: 14px !important;
  vertical-align: middle !important;
  padding:0;
  }
  
.analytisHas {
  margin-left: 30px;
  
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 10px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.containerAnalytics:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containerAnalytics input:checked ~ .checkmark {
  background-color: #F6315C;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containerAnalytics input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.containerAnalytics .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.whiteBg{
  background-color: #ededed;
  margin-top:-30px;
  padding:30px;
  border-radius: 20px !important;  
}
.whiteBgFunil{
  background-color: #3a3d47;
  height: 700px;
  width: 80%;
  margin-left: 200px;
  margin-top:-45px;
  padding: 30px;
  border-radius: 20px !important;  
}

.whiteBgCPL{
  background-color: #ededed;
  margin-top:-30px;
  width: 50%;
  padding:40px;
  border-radius: 20px !important;  
}

.noAnalytics{
  background-color: #ededed;
  height: 300px;
  width: 500px;
  margin-left: -100px !important;
  border-radius: 8px;
  color:#d5375a;
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  padding: 15px;
}
.noAnalytics p{
  margin-top:20%;
}
@media only screen and (max-width:1700px){
  .formProjecoes{
    width: 1300px;
    margin-left:340px;
  }
  .tituloProjecoes{
    font-size: 35px;
    margin-left: 850px;
  }
  .btnProjecoes{
    margin-left:505px;
  }

} 
@media only screen and (max-width:1600px){
  .chartPie{ 
    width: 900px !important;
  }

  .pchart{
   margin-left: -10px;
   margin-top:-40px;
  }
  
  .pchartTotal svg{
    margin-left:170px;
    margin-top:-70px;
    width: 600px;
   }


   .legendaRadial{
    margin-top:-190px;
   }
  .pchartMeta svg{
    margin-left: 170px;
    margin-top:-100px;
    width: 530px;
   }
 
  .pchartCPL svg{
    margin-left: 180px;
    margin-top:-40px;
    width: 530px;
   }
  .legenda{
    margin-top:-200px;
  }
  .legendafb{
    margin-top:-70px !important; 
  }

  .whiteBg{
    margin-top:0px;
    height: 500px;
  }
  .whiteBgCPL{
    margin-top:0px;
    height: 500px;
    width: 1000px !important;
  }
  .legendascpl{
    margin-top:-170px;
  }

  .whiteBgFunil{
    background-color: #ededed;
    height: 660px;

    margin-top:-50px;

  }
  .pchartBar svg{
    width: 700px;
    margin-top: -20px !important;
    margin-left: 100px;
    margin-bottom: 200px !important;
  }
  .formProjecoes{
    width: 1200px;
    margin-left:360px;
    max-height: 650px;
  }
  .inputProjecoes{
    width: 470px;
  }
  .inputProjecoes2{
    width: 470px;
  }

  .selectionProjecaoMes select{
    width: 470px;
  }
  .tituloProjecoes{
    font-size: 30px;
    margin-left: 810px;
  }
  .btnProjecoes{
    margin-left:375px;
  }
}



@media only screen and (max-width:1560px){

  .formProjecoes{
    overflow-x: hidden;
  }
  .metaFaturamentoTitle{
    margin-top: -20px !important;
  }

  .whiteBgFunil{
    background-color: #ededed;
    height: 600px;
  }
  .formProjecoes{
    width: 1100px;
    height:700px;
    margin-left:370px;
  }
  .selectionProjecao select{
    margin-left:200px;
  }

}

@media only screen and (max-width:1460px){
  .formProjecoes{
    width: 1000px;
    margin-left:370px;
  }
  .tituloProjecoes{
    font-size: 30px;
    margin-left: 750px;
  }

}

@media only screen and (max-width:1370px){
  .whiteBg{
    margin-top:15px;
    height: 400px;
  }
  .whiteBgCPL{
    margin-top:0px;
    height: 400px;
    width: 1000px !important;
  }
  .pchartMeta svg{
    margin-left: 170px;
    margin-top:-140px;
    width: 530px;
   }
   .legenda{
    margin-top:-270px;
  }
  .pchartBar svg{
    width: 600px;
    margin-top: -90px !important;
    margin-left: 135px;
  }
  .legendafb{
    margin-top:-170px !important; 
    margin-bottom: 100px;
  }
  
  .pchartTotal svg{
    margin-left:215px;
    margin-top:-110px;
    width: 500px;
   }

   .legendaRadial{
    margin-top:-250px;
   }

   .pchartCPL svg{
    margin-left: 215px;
    margin-top:-110px;
    width: 460px;
   }
   
   .legendascpl{
    margin-top:-240px;
  }
  .whiteBgFunil{
    background-color: #ededed;
    max-height: 400px;
    margin-left: 150px;
    margin-top: 10px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .formProjecoes{
    width: 900px;
    height: 600px;
    margin-left:370px;
  }
  .inputProjecoes{
    width: 300px;
    margin-left:70px;
  }
  .inputProjecoes2{
    width: 300px;
    margin-left:70px;
  }
  .formProjecoes label{
    font-size: 15px;
  }
  .selectionProjecao select{
    width: 300px;
    margin-left:70px;
  }
  .selectionProjecaoMes select{
    width: 300px;
    margin-left:70px;
  }
}

@media only screen and (max-width:1280px){
  .whiteBgFunil{
    background-color: #ededed;
    max-height: 400px;
    width: 1150px;
    margin-left: 70px;
    margin-top: 10px;
    overflow-y: auto;
  }
  .formProjecoes{
    width: 850px;
    height: 500px;
    margin-left:370px;
  }
  .tituloProjecoes{
    font-size: 30px;
    margin-left: 650px;
  }
}