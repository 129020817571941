/* The sidenav */
.sidenav {
  height: 100%;
  width: 300px !important;
  position: fixed;
  z-index: 999;
  top: 0;
  align-items: center !important;
  left: 0;
  background-color: #5C1515;
  overflow-x: hidden;
  padding-top: 20px;
}


/* Page content */
.main {
  margin-left: 200px; /* Same as the width of the sidenav */
}

.list{
  display: list-item !important; 
  color:#fff;

}

.singleButton{
  margin-top:18px;
  background-color: #fff;
  border:none !important;
  border-radius: 8px;
  height: 50px;
  width: 220px;
  color:#000;
  margin-left: 43px;
}

.singleButton a{
  color: rgb(117,54,63);
}
.singleButton:hover{
  background-color: rgb(255 255 255 / 45%) !important;
  font-weight: 600;
  color: black;
  border: 2px #ad0c00 !important;
  box-shadow: 0 0 10px #ad0c00 !important;
}

.singleButton:focus{
  background-color: rgb(117,54,63);
  box-shadow: none !important;
}

.singleButton:active{
  background-color: rgb(117,54,63);
  box-shadow: none !important;
}

.menuButton{
  margin-top:18px;
  background-color: #fff;
  border:none !important;
  border-radius: 8px;
  height: 50px;
  width: 220px;
  color:#000;
  margin-left: 15px;
}

.menuButton:hover{
  background-color: rgb(255, 0, 35);
  box-shadow: none !important;
}

.menuButton:focus{
  background-color: rgb(117,54,63);
  box-shadow: none !important;
}

.menuButton:active{
  background-color: rgb(117,54,63);
  box-shadow: none !important;
}

.primary.dropdown-toggle{
  background-color: rgb(117,54,63) !important;
  box-shadow: none !important;
}
.btn-primary:hover{
  background-color: rgb(255 255 255 / 45%) !important;
  font-weight: 600;
  color: black;
  border: 2px #ad0c00 !important;
  box-shadow: 0 0 10px #ad0c00 !important;
}
.btn-primary:active{
  background-color: rgb(255 255 255 / 45%) !important;
  font-weight: 600;
  color: black;
  border: 2px #ad0c00 !important;
  box-shadow: 0 0 10px #ad0c00 !important;
}
.btn-primary:focus{
  background-color: rgb(255 255 255 / 45%) !important;
  font-weight: 600;
  color: black;
  border: 2px #ad0c00 !important;
  box-shadow: 0 0 10px #ad0c00 !important;
}

.logog6{
  margin-top:0px;
  max-width: 150px;
  margin-left: 85px;
  margin-bottom:20px;
 
}

/* Dropdown Button */
.dropbtn {
  background-color: #3498DB;
  color: white;
  padding: 6px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* Dropdown button on hover & focus */
.dropbtn:hover, .dropbtn:focus {
  background-color: #2980B9;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  margin-left: 30px;
  font-size: 15px !important;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 170px !important;
  box-shadow: none !important;
  z-index: 1;
  margin-left: 0px !important;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 0px 0px !important;
  text-decoration: none;
  display: block;
}
a.dropdown-item{
  width: 220px;
  font-size: 14px !important;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #ddd}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {display:block;}

.primary.dropdown-toggle,.primary.dropdown-toggle:focus, .primary.dropdown-toggle:hover {
  color: #fff;
  background-color:rgb(117,54,63) !important;
  border-color:rgb(117,54,63) !important;
}
.profileAdmin{
  max-width: 100px;
  max-height: 100px;
  border-radius: 50%;
  margin-left: auto;
}

.nomePessoa{
  margin-top:40px;
  padding-bottom: 0px;
  margin-left: 0px;
  font-size: 20px;
  color:#fff;
  font-weight: 600;
  text-align: center;
}


@media only screen and (min-width:2000px){
  .menuButton{
    margin-top:28px;
  }
  .singleButton{
    margin-top:28px;
  }
  
}
@media only screen and (max-width:1600px){
  
.singleButton{
  height: 40px;
}
.menuButton{
  height: 40px;
}

}

@media only screen and (max-width:1370px){
  .singleButton{
    height: 35px;
  }
  .menuButton{
    height: 35px !important; 
  }
  
  .profileAdmin{
    max-width: 80px;
    max-height: 80px;
    margin-left: 85px;
  border-radius: 50%;
  }
  .nomePessoa{
    margin-top:30px;
    padding-bottom: 0px;
    margin-left: 0px;
    font-size: 18px;
  }
  .logog6{
    margin-top:0px;
    max-width: 120px;
    margin-left: 90px;
    margin-bottom:20px;
   
  }
  
}


@media only screen and (max-width:1280px){
  .singleButton{
    height: 30px;
  }
  .menuButton{
    height: 30px !important; 
  }
  
  .profileAdmin{
    max-width: 80px;
    max-height: 80px;
    margin-left: 113px;
  border-radius: 50%;
  }
  .nomePessoa{
    margin-top:30px;
    padding-bottom: 0px;
    margin-left: 0px;
    font-size: 18px;
  }
  .logog6{
    margin-top:0px;
    max-width: 120px;
    margin-left: 95px;
    margin-bottom:20px;
   
  }
  
}