:root {
  --primary:  #008bb2;

  --accent: #0935B3;

  --success: #09B37A;

  --error: #B33212;

  --warning: #B38612;

  --neutral: #000000;

  --white: #ffffff;
  --black: #000000;
}

body, input, textarea, button, select{
  letter-spacing: 0.1px;
}

.main-panel{
  background-color: var(--white);
  min-height: 100vh;
}

.ChangeBusinessModal{
  .botao-empresa{
    background-color: transparent;
    display: block;
    width: 100%;
    text-align: left;
    padding: 15px 5px;
    border: none;
    transition: background-color 0.2s;
    border-radius: 4px;
    &:hover{
      background-color: rgba(#09B37A, 0.25);
    }
  }
}