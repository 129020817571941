.formResultados{
  margin-left: 360px;
  border: 1px solid gray !important;
  width: 1500px;
  padding:50px;
  margin-top:20px;
  max-height: 800px;
  overflow-y:auto ;
}

.backDash{
  margin-top:30px;
  margin-left:30px;
  letter-spacing: 3px;
  z-index: 9999;
  color: #fff;
  background-color: transparent;
  border: none !important;
  outline:  none !important;
  font-size: 25px;
  position: fixed;
}

.tituloResultados{
  color: #d5375a;
  font-size: 38px;
  margin-left: 900px;
  font-weight: 600;
  margin-top:30px;
}

.formResultados label{
  font-size: 20px;
  padding: 10px;
  width: 400px;
}

.btnAnoLeads{
  background-color: transparent;
  border: none;
  margin-left: 60px;
  margin-top:10px;
  width: 30px;
  box-shadow: none;
}

.btnAnoLeads3{
  background-color: transparent;
  border: none;
  margin-top:0px;
  width: 30px;
  box-shadow: none;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  text-shadow:
  0 0 10px #d5385a,
  0 0 22px #d5385a,
  0 0 32px #d5385a;
  font-size: 20px;
}

.btnAnoLeads i{
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  text-shadow:
  0 0 10px #d5385a,
  0 0 22px #d5385a,
  0 0 32px #d5385a;
  font-size: 20px;
}

.btnAnoLeads2{
  background-color: transparent;
  border: none;
  margin-left: 30px;
  margin-top:10px;
  width: 30px;
  box-shadow: none;
}

.btnAnoLeads2 i{
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  text-shadow:
  0 0 10px #d5385a,
  0 0 22px #d5385a,
  0 0 32px #d5385a;
  font-size: 20px;
}
.chooseAno .modal-content{
    height: 300px;
    margin-top: 200px;
}

.chooseAno .closeButton{
  background-color: #D4365A;
  border: none !important;
}

.formResultados::-webkit-scrollbar {
  background-color: #ededed !important;
  width: 10px;
  }
  
  .formResultados::-webkit-scrollbar-thumb {
  background-color: #d5375a;
  border-radius: 25px;
  height: 5px;
  }
  .formResultados::-webkit-scrollbar-track {
  background-color: #c8c8c8;
  border: gray !important;
  height: 80%;
  border-radius: 25px;  
  }
  
  .formResultados::-webkit-scrollbar-track-piece{
  background-color: #c8c8c8 !important;
  }


.inputResultados{
  width: 850px;
  height: 40px;
  margin-left:150px;
  background-color: #c8c8c8;
  box-shadow: none !important;
  border: solid 1px gray;
}
.selectionResultados select{
  width: 850px;
  margin-left:150px;
  background-color: #c8c8c8;
  border: solid 1px gray;
}

.selectionResultadosSearch select{
  width: 850px;
  margin-left:30px;
  margin-top:20px;
  background-color: #c8c8c8;
  border: solid 1px gray;
}

.ResultadosButton{
  margin-left:305px;
  width: 160px;
  background-color: #d5375a;
  border: none !important;
  border-radius: 5px;
  height:40px;
}
.ResultadosButton::placeholder{
  color:#fff;
  font-weight: 500;
  text-align: center;
  border:none !important;
  box-shadow: none !important;
  outline:none !important;
}

.ResultadosButton:focus::placeholder{
  color:#8c8c8c;
  font-weight: 500;
  text-align: center;
  border:none !important;
  box-shadow: none !important;
  outline:none !important;
}

.ResultadosButton:focus{
  background-color: #fff;
  border:none !important;
  box-shadow: none !important;
  outline:none !important;
}

.btnResultados{
  background-color: #d5375a;
  color:#fff;
  box-shadow: none !important;
  outline: none !important;
  border: none;
  height:45px;
  font-size: 20px;
  margin-left: 600px;
}

.gerenciaResultados{
  padding-top:50px;
  width: 1500px;
  margin-left:330px;
  padding-right:0px;
  max-height: 700px;
  overflow-y: auto !important;
  border: solid 1px gray;
}

.gerenciaResultados table{
  margin-left: 70px;
}
.gerenciaResultados table th{
  width: 200px;
} 

.gerenciaResultados table td{
  height: 50px;
}

.gerenciaResultados::-webkit-scrollbar {
  background-color: #ededed !important;
  width: 10px;
  }
  
  .gerenciaResultados::-webkit-scrollbar-thumb {
  background-color: #d5375a;
  border-radius: 25px;
  height: 5px;
  }
  .gerenciaResultados::-webkit-scrollbar-track {
  background-color: #c8c8c8;
  border: gray !important;
  height: 80%;
  border-radius: 25px;  
  }
  
  .gerenciaResultados::-webkit-scrollbar-track-piece{
  background-color: #c8c8c8 !important;
  }


.titleResultadosEdit{
  color: #d5375a;
  font-size: 38px;
  margin-left: 900px;
  font-weight: 600;
  margin-top:30px;
}

.resultadosLeads .modal-content{
  background-color: #c8c8c8;
  margin-left: -100px;
  width: 1000px;
  border-top-left-radius: 15px;
border-top-right-radius: 15px;
}
.titleResultadosModal{
  color: #d5375a;
  font-size: 30px;
  margin-left: 360px;
  font-weight: 600;
  margin-top:0px;
}

.resultadosLeads .modal-content .closeButton{
  background-color: #D4365A;
border:none !important;
font-weight: 600;
width: 100%;
border-top-left-radius: 15px;
border-top-right-radius: 15px;
}

.avisoResultado{
  width: 180%;
  margin-left: 780px;
}

.deleteBotao{
  background-color:rgb(117,54,63);
  color:#fff;
  border: none !important;
  border-radius: 8px;
  padding-left: 10px;
  margin-left: 10px;
}
.leadsInput{
  width: 250px !important;
  height: 40px;
  background-color: #c8c8c8;
  box-shadow: none !important;
  border: solid 1px gray;
}

.leadsInput select{
  width: 250px !important;
  height: 40px;
  margin-left:150px;
  background-color: #c8c8c8;
  box-shadow: none !important;
  border: solid 1px gray;
}

.formResults{
  margin-top:30px;
}
.selectionResultadosEdit select{
  width: 250px;
  margin-left:0px;
  background-color: #c8c8c8;
  border: solid 1px gray;
}
.empresaLeads{
  margin-left: 50px;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
}

.alterarLeads{
  color:#F6315C;
  font-weight: 500;
  background-color: transparent;
  border: none !important;
}

.btneditResultados{
  background-color: #d5375a;
  color:#fff;
  box-shadow: none !important;
  outline: none !important;
  border: none;
  height:45px;
  font-size: 20px;
}

.ResultadosButtonEd{
  margin-top:30px;
  width: 160px;
  background-color: #d5375a;
  border: none !important;
  border-radius: 5px;
  height:40px;
}

.ResultadosButtonEd::placeholder{
  color:#fff;
  font-weight: 500;
  text-align: center;
  border:none !important;
  box-shadow: none !important;
  outline:none !important;
}

.ResultadosButtonEd:focus::placeholder{
  color:#8c8c8c;
  font-weight: 500;
  text-align: center;
  border:none !important;
  box-shadow: none !important;
  outline:none !important;
}

.ResultadosButtonEd:focus{
  background-color: #fff;
  border:none !important;
  box-shadow: none !important;
  outline:none !important;
}
.printResultados{
  background-color: #fff;
  border-radius: 30px;
  height: 30px;
  width: 30px;
  padding: 0;
  margin-top:-30px;
  border:none !important;
  position:absolute;
  margin-left:20px;
}

.printResultados i{
  font-size: 20px;
  padding: 0;
  color: #F6315C !important;
}

.deleteAviso .modal-content{
  background-color: #ededed;
}

.deleteAviso .modal-content i{
  color: #F6315C;
}
.areYouSure{
  background-color: #F6315C;
  color:#fff;
  border: none !important;
  padding:5px;
  border-radius: 8px;
}
.notSure{
  background-color: #FE799D;
  color:#fff;
  border: none;
  padding: 5px;
  border-radius: 8px;
  margin-left: 10px;
}

.deleteAviso .modal-content .closeButton{
  background-color: #D4365A;
  border: none !important;
  outline: none !important;
}


@media only screen and (max-width:1700px){
.formResultados{
  margin-left: 370px;
  width: 1250px;
}
.selectionResultados select{
  width: 650px;
}
.inputResultados{
  width: 650px;
}
.ResultadosButton{
  margin-left:225px;
  width: 130px;
}

.btnResultados{
  margin-left: 530px;
}
.gerenciaResultados{
  width: 1250px;
  margin-left:370px;
}
.gerenciaResultados table{
  margin-left: 0px !important;
}
.titleResultadosEdit{
  margin-left: 800px;
}

}

@media only screen and (max-width:1600px){
  .formResultados{
    margin-left: 385px;
    width: 1150px;
  }
  .selectionResultados select{
    width: 600px;
  }
  .inputResultados{
    width: 600px !important; 
  }
  
.btnResultados{
  margin-left: 460px;
}
.tituloResultados{
  margin-left: 840px;
}
.avisoResultado{
  margin-left: 730px;
}

.gerenciaResultados{
  width: 1150px;
  margin-left:385px;
}
.gerenciaResultados table{
  margin-left: 0px !important;
}
.titleResultadosEdit{
  margin-left: 800px;
}
}

@media only screen and (max-width:1540px){
  .formResultados{
    margin-left: 390px;
    width: 1080px;
  }
  .inputResultados{
    width: 510px !important; 
    margin-left: 180px;
  }
  .selectionResultados select{
    width: 510px;
    margin-left: 180px;
  }
  .btnResultados{
    margin-left: 430px;
  }
  .ResultadosButton{
    margin-left:205px;
    width: 130px;
  }
  .gerenciaResultados{
    width: 1080px;
    margin-left:390px;
  }
  .gerenciaResultados table{
    margin-left: 0px !important;
    margin-top: 40px;
  }
  .titleResultadosEdit{
    margin-left: 750px;
  }
}

@media only screen and (max-width:1450px){
  .formResultados{
    margin-left: 390px;
    width: 980px;
  }
  .inputResultados{
    width: 440px !important; 
    margin-left: 190px;
  }
  .selectionResultados select{
    width: 440px;
    margin-left: 190px;
  }
  .btnResultados{
    margin-left: 360px;
  }
  .ResultadosButton{
    margin-left:180px;
    width: 120px;
  }
  .gerenciaResultados{
    width: 1080px;
    margin-left:330px;
  }
  .titleResultadosEdit{
    margin-left: 710px;
    font-size: 35px;
  }
}



@media only screen and (max-width:1370px){
  .formResultados{
    margin-left: 430px;
    width: 850px;
    height: 550px;
  }
  .formResultados label{
    font-size: 15px;
  }
  .tituloResultados{
    margin-left:720px;
  }
  .avisoResultado{
    margin-left: 620px;
  }
  .inputResultados{
    width: 380px !important; 
    margin-left: 150px;
  }
  .selectionResultados select{
    width: 380px;
    margin-left: 150px;
  }
  .ResultadosButton{
    margin-left:150px;
    width: 120px;
  }
  .btnResultados{
    font-size: 15px;
    margin-left: 320px;
    height: 30px;
    padding: 0;
    padding-left: 10px;
    padding-right: 10px;
  }
  .gerenciaResultados{
    width: 930px;
    max-height: 500px;
    margin-left:380px;
  }
  .gerenciaResultados table{
    margin-left: 0px !important;
    margin-top: 40px;
  }
  .titleResultadosEdit{
    margin-left: 710px;
    font-size: 30px;
  }
}
@media only screen and (max-width:1280px){
  .formResultados{
    margin-left: 375px;
  }
  .tituloResultados{
    margin-left:660px;
  }
  .avisoResultado{
    margin-left: 560px;
  }
  .btnResultados{
    margin-top:20px;
  }
  .gerenciaResultados{
    margin-left:330px;
  }
  .titleResultadosEdit{
    margin-left: 690px;
    font-size: 30px;
  }
}