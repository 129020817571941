.whiteNoticias{
  min-height: 300px;
  height: auto;
  width: 600px;
  background-color: #ededed !important;
  border-radius: 12px;
  margin-top:80px;
  box-shadow: 
  0 0 0.6rem #9D2727,
  0 0 0.5rem #9D2727,
  0 0 0rem #9D2727,
  inset 0 0 0 #9D2727;
}
.noticiaDoDia{
  font-size: 50px;
  margin-top:100px;
  font-weight: 800;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  text-shadow:
  0 0 10px #d5385a,
  0 0 22px #d5385a;
}

.tituloNot{
  color:#414141;
  font-size: 25px;
}

.noticiaBody{
  text-align: center;
  margin-top:40px;
}

.btnNoticia{
  background-color: #d5385a;
  color: #fff;
  border: none !important;
  height: 50px;
  margin-top:80px;
  border-radius: 8px;
  padding: 10px;
  
}

@media only screen and (max-width:1300px){
  .noticiaDoDia{
    margin-top:60px;
  }
}