#gerenciaNegocio{
  padding-top:50px;
  width: 1500px;
  margin-left:330px;
  padding-right:0px;
  max-height: 780px;
  overflow-y: auto;
}

.tr-border{
  border-bottom: dotted 1px #D4365A;
}

#gerenciaNegocio table{
  margin-left: 70px;
}
#gerenciaNegocio table th{
  width: 200px;
} 

#gerenciaNegocio table td{
  height: 50px;
}

.edicaoNegocio label{
  padding-top: 14px !important;
}


.opcoesTd{
  width: 300px !important;
}
.filter{
  background-color: #EF232C !important;
  color:#fff;
  border:none !important;
  border-radius: 8px;
  padding-left: 10px;
  margin-left:10px;
}

.editar{
  background-color: #F1DEE6 !important;
  color:gray !important; 
  border-radius: 8px !important;
  width: 70px;
  border:none !important;
}

.deletar{
  background-color: #d5375a !important;
  border: none !important;
  border-radius: 8px;
  width: 70px;
  color:#fff;
  font-weight: 500;
  margin-left: 10px;
}

.edicaoNegocio .modal-content{
  width: 900px;
  margin-left: -140px;
  border:none !important;
  background-color: #c8c8c8;
  border-radius: 12px;
}

.edicaoNegocio .modal-content .closeButton{
  background-color: #d5375a;
  border: none !important;
  font-weight: 500;
  z-index: 2;
}
.edicaoNegocio:hover .modal-content:hover .closeButton:hover{
  background-color: #d5375a !important;
}

.edicaoNegocio .modal-body{
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: .3rem;
  box-shadow: 0 0 0 50vmax rgba(0,0,0,.5);}

.edicaoNegocio .modal-body::-webkit-scrollbar {
  background-color: #ededed !important;
  width: 10px;
  }
  
  .edicaoNegocio .modal-body::-webkit-scrollbar-thumb {
  background-color: #d5375a;
  border-radius: 25px;
  height: 5px;
  }
  .edicaoNegocio .modal-body::-webkit-scrollbar-track {
  background-color: #c8c8c8;
  border: gray !important;
  height: 80%;
  border-radius: 25px;  
  }
  
  .edicaoNegocio .modal-body::-webkit-scrollbar-track-piece{
  background-color: #c8c8c8 !important;
  }


.inputNegocioEdit{
  width: 500px;
  background-color: #c8c8c8;
  outline: none !important;
  height: 40px;
  margin-top:10px;
  border: 1px solid gray;
  box-shadow: none !important;
}

.selectNegociosEdit{
  width: 500px;
  background-color: #c8c8c8;
  outline: none !important;
  border: 1px solid gray;
  margin-top:10px;
  box-shadow: none !important;
}

.selectNegociosEdit select{
  background-color: #c8c8c8 !important;
  outline: none !important;
  border: 1px solid gray;
  box-shadow: none !important;
}


.buttonNegocioEdit{
  margin-left:80px;
  width: 160px;
  background-color: #d5375a;
  border: none !important;
  border-radius: 5px;
  height:40px;
  margin-top:10px;
}
.buttonNegocioEdit::placeholder{
  color:#fff;
  font-weight: 500;
  text-align: center;
}

.buttonNegocioEdit:focus::placeholder{
  color:#8c8c8c;
  font-weight: 500;
  text-align: center;
}

.plusMembrosEdit{
  margin-left:80px;
  background-color: #d5375a;
  width: 160px;
  border:none !important;
  height:40px;
  margin-top:10px;
  color:#fff;
  font-weight: 500;
  border-radius: 5px;
}

.plusMembrosEdit::placeholder{
  color:#fff;
  font-weight: 500;
  text-align: center;
}

.plusMembrosEdit:focus::placeholder{
  color:#8c8c8c;
  font-weight: 500;
  text-align: center;
}

.plusMembrosEdit:focus{
  background-color: #c8c8c8;
  border:none !important;
  outline: none !important;
}

.btnEditNeg{
  background-color: #d5375a;
  color:#fff;
  box-shadow: none !important;
  outline: none !important;
  border: none;
  height:45px;
  font-size: 20px;
}

.tituloENegocio{
  font-size: 25px;
  text-align: center;
  color:#d5375a;
  font-weight: 600;
  margin-bottom: 20px;
}

.logo{
  background-color: rgb(117,54,63) !important;
  border: none !important;
  border-radius: 8px;
  width: 75px;
  color:#fff;
  font-weight: 500;
  margin-left: 10px;
}

.perfile{
  max-width: 300px;
}

.logotipo{
  margin-left: 60px;
}

.newLogo{
  width: 300px;
  margin-top: 10px;
  background-color: #d5375a;
  border:none !important;
  box-shadow: none !important;
}


.logoModal .modal-content{
  width: 500px;
  border:none !important;
  background-color: #c8c8c8;
  border-radius: 12px;
}

.logoModal .modal-content .closeButton{
  background-color: #c8c8c8;
  border: none !important;
}

.logoModal .modal-body{
  max-height: 800px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: 40px;
}

.containerEditBuzz [type=checkbox] {
  width: 1rem;
  height: 1rem;
  color: #d5375a;
  vertical-align: middle;
  -webkit-appearance: none;
  background: none;
  border: 0;
  outline: 0;
  margin-left: 10px;
  flex-grow: 0;
  border-radius: 0%;
  background-color: #FFFFFF;
  transition: background 300ms;
  cursor: pointer;
}

.containerEditBuzz [type=checkbox]::before {
  content: "";
  color: transparent;
  display: block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  border: 0;
  background-color: transparent;
  background-size: contain;
  box-shadow: inset 0 0 0 1px #CCD3D8;
}

.containerEditBuzz [type=checkbox]:checked {
  background-color: currentcolor;
}

.containerEditBuzz [type=checkbox]:checked::before {
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
}

.containerEditBuzz [type=checkbox]:disabled {
  background-color: #CCD3D8;
  opacity: 0.84;
  cursor: not-allowed;
}

.containerEditBuzz [type=checkbox]::-ms-check {
  content: "";
  color: transparent;
  display: block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  border: 0;
  background-color: transparent;
  background-size: contain;
  box-shadow: inset 0 0 0 1px #CCD3D8;
}

.containerEditBuzz [type=checkbox]:checked::-ms-check {
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
}

@media only screen and (max-width:1700px){
#gerenciaNegocio{
  padding-top:50px;
  width: 1300px;
}
  #gerenciaNegocio table{
    margin-left: -10px;
  }
.arquivosModal .modal-content{
  margin-left: 200px;
}
.edicaoNegocio .modal-content{
  margin-left: -80px;
}
}

@media only screen and (max-width:1600px){
  #gerenciaNegocio{
    padding-top:50px;
    width: 1050px;
    margin-left: 400px;
  }
  #gerenciaNegocio table{
 font-size: 15px;
  }
  #gerenciaNegocio table td{
    height: 70px;
  }
  .logo{
    margin-top:10px;
  }
  .edicaoNegocio .modal-content{
    max-height: 650px;
    margin-top:60px !important;
  }
}

@media only screen and (max-width:1450px){
  #gerenciaNegocio{
    margin-left: 350px;
  }
}
@media only screen and (max-width:1370px){
  #gerenciaNegocio{
    margin-left: 310px;
  }
}
@media only screen and (max-width:1280px){
  #gerenciaNegocio{
    margin-left: 315px;
    width: 950px;
  }
  .edicaoNegocio .modal-content{
    max-height: 550px;
    margin-top:80px !important;
  }
}