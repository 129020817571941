
.menuRegisterData{
  margin-left: 360px;
  border: 1px solid gray !important;
  width: 1500px;
  padding:50px;
  margin-top:20px;
  max-height: 800px;
  overflow-y:auto ;
}

.menuRegisterData::-webkit-scrollbar {
  background-color: #ededed !important;
  width: 10px;
  }
  
  .menuRegisterData::-webkit-scrollbar-thumb {
  background-color: #D4365A;
  border-radius: 25px;
  height: 5px;
  }
  .menuRegisterData::-webkit-scrollbar-track {
  background-color: #c8c8c8;
  border: gray !important;
  height: 80%;
  border-radius: 25px;  
  }
  
  .menuRegisterData::-webkit-scrollbar-track-piece{
  background-color: #c8c8c8 !important;
  }


.menuRegisterData label{
  font-size: 20px;
  padding: 10px;
}

.miniTitle{
  color: #D4365A;
  font-size: 22px;
  font-weight: 600;
  margin-top:10px;
}

.inputDados{
  width: 1000px;
  height: 40px;
  background-color: #c8c8c8;
  box-shadow: none !important;
  border: solid 1px gray;
}

.inputDados:focus{
  outline: none !important;
  box-shadow: none !important;
}
