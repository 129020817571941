.titleGerenciaEquipe{
  font-size: 38px;
  margin-left:900px;
  margin-top:100px;
  font-weight: 600;
  color: #d5375a;
}

.equipeFotos{
  margin-top: 70px;
  
}

.equipeFotos  ul{
  list-style: none;
  display: inline-block !important;
  
}

.equipeFotos li{
  display:table-cell !important;
  width: 120px;
  height: 150px !important;
}

.equipebtn{
  border-radius: 50%;
  border: solid 1px #ededed;
  width: 100px;
  background-color: #ededed;
  height: 100px;
  padding: 0 !important;
}

.equipeFotos img{
  max-width: 80px;
  object-fit: cover;
}

.nomeEquipe{
  text-align: center;
  font-weight: 500;
  color:#d5375a;
}

.gerenEquipeModal .modal-content{
  background-color: #ededed;
  height: 300px;
  width: 900px;
  margin-top: 100px;
  border: none !important;
  margin-left: -150px;
}

.gerenEquipeModal .modal-content .closeButton{
  background-color: #d5375a;
  border: none !important;
}

.listaPermissoes{
  display: table-cell;
}

/* Basic styling */


.containerEquipe [type=checkbox] {
  width: 2rem;
  height: 2rem;
  color: #d5375a;
  vertical-align: middle;
  -webkit-appearance: none;
  background: none;
  border: 0;
  outline: 0;
  margin-left: 10px;
  flex-grow: 0;
  border-radius: 0%;
  background-color: #FFFFFF;
  transition: background 300ms;
  cursor: pointer;
}



/* Pseudo element for check styling */


.containerEquipe [type=checkbox]::before {
  content: "";
  color: transparent;
  display: block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  border: 0;
  background-color: transparent;
  background-size: contain;
  box-shadow: inset 0 0 0 1px #CCD3D8;
}


/* Checked */


.containerEquipe [type=checkbox]:checked {
  background-color: currentcolor;
}


.containerEquipe [type=checkbox]:checked::before {
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
}


/* Disabled */


.containerEquipe [type=checkbox]:disabled {
  background-color: #CCD3D8;
  opacity: 0.84;
  cursor: not-allowed;
}


/* IE */


.containerEquipe [type=checkbox]::-ms-check {
  content: "";
  color: transparent;
  display: block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  border: 0;
  background-color: transparent;
  background-size: contain;
  box-shadow: inset 0 0 0 1px #CCD3D8;
}


.containerEquipe [type=checkbox]:checked::-ms-check {
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
}

.subtitlee{
  margin-top:20px;
  font-size: 20px;
  margin-bottom:20px;
}

.listaPermissoesEdit{
  margin-top:40px;
}

.centerAlign{
  text-align: center;
  color:#ededed;
  font-size: 20px;
}

@media only screen and (max-width:1700px){
  .titleGerenciaEquipe{
    margin-left:820px;
  }
}

@media only screen and (max-width:1540px){
  .titleGerenciaEquipe{
    margin-left:730px;
  }
}

@media only screen and (max-width:1370px){
  .titleGerenciaEquipe{
    margin-left:650px;
    font-size: 30px;
  }
}