#gerenciar_users{
  padding-top:50px;
  width: 1500px;
  margin-left:350px;
  padding-right:0px;
  max-height: 800px !important;
  overflow-x: auto;
}
.tr-border{
    border-bottom: solid 2px #a1a1a1;
}

.supButton{
    display: none;
}

#gerenciar_users table{
  margin-left: 70px;
}

#gerenciar_users::-webkit-scrollbar {
  background-color: #ededed !important;
  width: 10px;
  }
  
  #gerenciar_users::-webkit-scrollbar-thumb {
  background-color: #D4365A;
  border-radius: 25px;
  height: 5px;
  }
  #gerenciar_users::-webkit-scrollbar-track {
  background-color: #c8c8c8;
  border: gray !important;
  height: 80%;
  border-radius: 25px;  
  }
  
  #gerenciar_users::-webkit-scrollbar-track-piece{
  background-color: #c8c8c8 !important;
  }

#gerenciar_users .emp{
  width: 600px;
}

#gerenciar_users .usr{
  width: 250px;
}
#gerenciar_users .usr_nome{
  width: 250px;
}



.srch{
  margin-left: -200px !important;
  margin-top:-50px;
}
.tituloGerenciaUsers{
  margin-top:50px;
  font-size: 38px;
  font-weight: 600;
  color: #EF232C;
  margin-left: 900px;
}

.inativar{
  background-color: #8c8c8c;
  color:#fff;
  border:none !important;
  border-radius: 8px;
  margin-left: 10px;
  font-weight: 600;
}

.ativar{
  background-color: #05AB6D;
  color:#fff;
  border:none !important;
  border-radius: 8px;
  margin-left: 10px;
  font-weight: 600;
}

.deletarUser{
  background-color: #EF232C;
  color: #fff;
  font-weight: 600;
  border: none !important;
  border-radius: 8px;
  margin-left: 10px;
  margin-top:10px;
  margin-bottom:10px;
}

.filtering{
  margin-left:70px;

}

.gerenUser .modal-content{
  width: 1200px;
  margin-left: -220px;
  border:none !important;
  background-color: #c8c8c8;
  border-radius: 12px;

}

.gerenUser .modal-content .closeButton{
    background-color: #d5375a;
    border: none !important;
    font-weight: 500;
    z-index: 2;
}
.gerenUser:hover .modal-content:hover .closeButton:hover{
    background-color: #d5375a !important;
}

.gerenUser .modal-body{
    max-height: 800px;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: .3rem;
    box-shadow: 0 0 0 50vmax rgba(0,0,0,.5);
}

.gerenUser .modal-body::-webkit-scrollbar {
  background-color: #ededed !important;
  width: 10px;
  }
  
  .gerenUser .modal-body::-webkit-scrollbar-thumb {
  background-color: #d5375a;
  border-radius: 25px;
  height: 5px;
  }
  .gerenUser .modal-body::-webkit-scrollbar-track {
  background-color: #c8c8c8;
  border: gray !important;
  height: 80%;
  border-radius: 25px;  
  }
  
  .gerenUser .modal-body::-webkit-scrollbar-track-piece{
  background-color: #c8c8c8 !important;
  }


  .titleGenUser{
    color: #d5375a;
    font-size: 30px;
    font-weight: 600;
    margin-top:30px;
  }


.listA td{
  width:300px;
}

.Remove{
  background-color: #D4365A !important;
  color: #fff;
  border:none !important;
  padding:6px;
  margin-top:10px;
  border-radius: 6px;
}

.botaoRosa{
  background-color: #D4365A;
  border: none;
}
@media only screen and (min-width:2000px){
  #gerenciar_users{
    margin-left:410px;
  }
  .tituloGerenciaUsers{
    margin-left: 970px;
  }
  
  
}
@media only screen and (max-width:1700px){
  #gerenciar_users{
    padding-top:50px;
    width: 1300px;
    margin-left:350px;
    padding-right:0px;
    max-height: 800px !important;
    overflow-x: auto;
    border: solid 1px gray;
  }
 #gerenciar_users .usr_nome{
  width: 140px;
 }

 #gerenciar_users .emp{
  width: 510px;
 }
 
 #search_box{
  width: 140px !important;
 }
 .tituloGerenciaUsers{
  margin-left: 790px;
 }
}
@media only screen and (max-width:1600px){
  #gerenciar_users{
    width: 1200px;
    max-height: 700px !important;
  }
  #gerenciar_users .emp{
    width: 430px;
   }
   #search_box{
    width: 100px !important;
   }
   .tituloGerenciaUsers{
    margin-left: 790px;
   }
}

@media only screen and (max-width:1545px){
  #gerenciar_users{
    width: 1100px;
    margin-left: 380px;
  }
  #gerenciar_users .emp{
    width: 350px;
   }
   .tituloGerenciaUsers{
    margin-left: 770px;
    font-size: 35px;
   }
   .gerenUser .modal-content{
    width: 1000px;
    margin-left: -90px;
  }
  .gerenUser .modal-body{
    max-height: 700px;
  }
}
@media only screen and (max-width:1445px){
  .tituloGerenciaUsers{
    margin-left: 750px;
    font-size: 30px;
   }
   #gerenciar_users{
    width: 1000px;
    margin-left: 385px;
    font-size: 14px;
  }
  #gerenciar_users .usr_nome{
    width: 100px;
   }
  #gerenciar_users .emp{
    width: 300px;
   }
}

@media only screen and (max-width:1370px){
  #gerenciar_users{
    width: 900px;
    height: 600px;
    margin-left: 380px;
  }
  .tituloGerenciaUsers{
    margin-left: 710px;
    font-size: 30px;
   }
   #search_box{
    width: 50px !important;
   }
   .gerenUser .modal-content{
    width: 900px;
    margin-left: -50px;
  }
  .gerenUser .modal-body{
    max-height: 500px;
  }
}
@media only screen and (max-width:1280px){
  #gerenciar_users{
    margin-left: 350px;
  }
  .tituloGerenciaUsers{
    margin-left: 660px;
    font-size: 30px;
   }
}