.editcalendario .modal-content{
    width: 1550px;
    margin-left: -380px;
    margin-top:-30px;
    background-color: #c8c8c8;
}

.editcalendario .modal-body{
  max-height: 900px;
  overflow-y: auto;
}

.editcalendario .modal-content .closeButton{
background-color: #D4365A;
border:none !important;
font-weight: 600;
}

.editcalendario .modal-body::-webkit-scrollbar {
  background-color: #ededed !important;
  width: 10px;
  }
  
  .editcalendario .modal-body::-webkit-scrollbar-thumb {
  background-color: #D4365A;
  border-radius: 25px;
  height: 5px;
  }
  .editcalendario .modal-body::-webkit-scrollbar-track {
  background-color: #c8c8c8;
  border: gray !important;
  height: 80%;
  border-radius: 25px;  
  }
  
  .editcalendario .modal-body::-webkit-scrollbar-track-piece{
  background-color: #c8c8c8 !important;
  }

.tituloEditCal{
  font-size: 38px;
  color:#d5375a;  
  text-align: center;
  margin-left: 200px;
  padding-top:100px;
  font-weight: 600;
}

.titleEditCalendario{
  color: #D4365A;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
}

.formCalendario{
  margin-left: 70px;
}

.calendarioInput{
  box-shadow: none !important;
  outline: none !important;
  border:solid 1px #8c8c8c !important;
  padding:10px;
  margin-left: 10px;
  width: 250px;
}

.calendarioSelection{
  width: 1500px;
}

.day1{
  width: 180px;
  font-size: 12px;
}

.editCalendar{
  background-color: #fff;
  border-radius: 40px;
  width: 40px;
  height: 40px;
  color: #008BB2;
  font-size: 25px;
  border:none;
  margin-right: 10px;
  margin-left:-10px;
}


.newCalendar{
  background-color: #fff;
  border-radius: 40px;
  width: 40px;
  height: 40px;
  color: #008BB2;
  font-size: 25px;
  border:none;
  margin-right: 20px;
  margin-left:0px;

}
textarea {
  resize: none;
}
.btnCalendario{
  background-color: rgb(117,54,63);
  color:#fff;
  box-shadow: none !important;
  outline: none !important;
  border: none;
  height:45px;
  font-size: 20px;
}

.deleteCalendario{
  background-color: rgb(117,54,63);
  border:none !important;
  color:#fff;
  margin-left:100px;
  margin-bottom: 20px;
  font-weight: 600;
  padding:10px;
  border-radius: 8px;
}
.dropCal{ 
  padding-left: 10px;
  padding-right: 10px;
}

.img-cal{
  width: 100px;
  height: auto;
  padding:20px;
}

.box-img{
  width: 100px;
  height: 100px;
  border: 1px solid #D4365A;
}

.inputCal{
  margin-bottom:10px;
  border-radius: 6px;
  width: 102px;
  text-align: center;
  margin-left: 10px;
  border: 1px solid #D4365A !important;
  outline: none !important;
}
.inputCal input{
  border:none !important;
  width: 100px;
  border-radius: 6px;
  text-align: center;
}

.tcal{
  margin-left: 20px;
}

.containerPost{
  width: 250px;
  text-align: center;
  padding-left:5px;
}


.txtPost{
  margin-top:-27px;
  margin-left: 25px;
}

.checkboxPostad{
  -webkit-appearance: none;
	background-color: #ffffff;
	border: 1px solid #cacece;
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
	padding: 0px;
	border-radius: 3px;
  width: 20px;
  height: 20px;
  margin-left: -60px;
	display: inline-block;
	position: relative;
}

.checkboxPostad:active, .regular-checkbox:checked:active {
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
}

.checkboxPostad:checked {
	background-color: #e9ecee;
	border: 1px solid #adb8c0;
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
	color: #D4365A;
}

.checkboxPostad:checked:after {
	content: '\2714';
	font-size: 14px;
	position: absolute;
	top: 0px;
	left: 3px;
	color: #D4365A;
}

.noPlans{
  background-color: #ededed;
  width: 600px;
  font-size: 28px;
  height: 300px;
  color:#D4365A;
  font-weight: 500;
  border-radius: 8px;
  margin-top:60px;
}

.noPlans p{
  padding: 30px;
  margin-top:17%;
}

.retrun{
  max-height: 610px !important;
  overflow-y: hidden !important;
}


@media only screen and (max-width:1700px){

.editcalendario .modal-content{
  width: 1350px;
  margin-left: -380px;
  margin-top:0px;
  background-color: #c8c8c8;
}

.formCalendario{
  margin-left: 165px;
}

.plannerSelection{
  width: 900px;
}
.btnCalendario{
  margin-left: -120px;
}
}

@media only screen and (max-width:1600px){
  .editcalendario .modal-content{
    max-height: 700px;
}


.tituloEditCal{
  padding-top:50px;
  margin-bottom: -30px;
  margin-left: -10;
}
}

@media only screen and (max-width:1440px){
  .editcalendario .modal-content{
   margin-left:-420px;
}
.tituloEditCal{
  font-size: 30px;
  margin-bottom:0px;
}
}

@media only screen and (max-width:1370px){
  .editcalendario .modal-content{
    width: 1100px;
    margin-left: -300px;
  
  }
}

@media only screen and (max-width:1280px){
  .editcalendario .modal-content{
    width: 1100px;
    max-height: 500px;
    margin-left: -300px;
  }
  
.plannerSelection{
  width: 700px;
}
}