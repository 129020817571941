#materiais{
  padding-top:50px;
  width: 1300px;
  margin-left:430px;
  padding-right:0px;
}
#materiaisAdd{
  padding-top:50px;
  width: 1300px;
  margin-left:430px;
  padding-right:0px;
}

.tableMateriais{
  max-height: 900px;
  border: solid 1px gray;
}
.tableMateriais th{
  width: 200px;
} 

.tableMateriais td{
  height: 50px;
}

.materiaisBtn{
  background-color:#D4365A;
  color:#fff;
  border:none !important;
  padding: 5px;
  border-radius: 8px;
  margin-left: 60px;
}

.botaoMat{
  background-color: #D4365A;
  color:#fff;
  font-weight: 600;
  border:none !important;
  box-shadow: none !important;
  outline: none !important;
  margin-left:18px;
  width: 150px;
}
.MatDrop{
  width: 190px;
  margin-top:0px;
  margin-bottom:20px;
  background-color: #D4365A;
  border:none !important;
  margin-left: -30px;
}
.drop{
  width: 190px;
}

.inputMat{
  border-radius: 8px;
  outline: none !important;
  box-shadow: none !important;
  border:solid 1px gray !important;
  margin-left:18px;
  padding-top:10px;
  width: 150px;
  margin-top:10px;
}

.labelMat{
  text-align: center;
  width: 170px;
}
.tituloMat{
  font-size: 38px;
  color:#d5375a;  
  text-align: center;
  margin-left: 120px;
  padding-top:100px;
  font-weight: 600;
}

.selectMat{
  width: 150px;
  margin-left:7px;
}
#search_boxMateriais{
  margin-top: 20px;
}
.rounded-rightMat{
  margin-top:20px;
}

#search_box{
  width: 150px;
  margin-top: 50px;
}
.rounded-right{
  height: 30px;
  width: 30px;
  margin-top: 50px;
}

.editMat{
  width: 700px;
  margin-left:0px;
}

.edicaoMat .modal-content{
  background-color: #c8c8c8;
  width: 250px;
  margin-left: 800px;
  margin-top:200px;
  border: solid 1px gray !important;
}


.edicaoMat .modal-content .closeButton{
  background-color: #c8c8c8;
  width: 30px;
  box-shadow: none !important;
  outline: none !important;
  border:none !important;
  margin-left: 110px;
  padding:0;
  margin-top:20px;
}

.edicaoMat .modal-content .closeButton:focus{
  background-color: #D4365A !important;
}
.edicaoMat .modal-content .closeButton:hover{
  background-color: #D4365A !important;
}
.edicaoMat .modal-body{
  align-items: center !important;
  margin-left: 40px;
}

.edicaoMat .modal-body label{
 text-align: center;
 margin-top: 10px;
}
.edicaoMat .modal-body{
  margin-left: 20px;
 }

.botaoEditMat{
  width: 185px;
  margin-top:30px;
  margin-bottom: 10px;
  background-color: #D4365A;
  box-shadow: none !important;
  border:none !important;
  outline: none !important;
}

.grayClass{
  background-color: #8c8c8c;
}
.linkMat{
  color:#D4365A;
  font-weight: 600;
  overflow: hidden;
  width: 250px;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 1; /* number of lines to show */
   -webkit-box-orient: vertical;
}
.linkMat:hover{
  color:#000;
  text-decoration: none !important;
  font-weight: 600;
}

.menuMateriais{
  margin-top:100px;

}

.menuMateriais ul li{
  display: inline-block;
  margin-left:30px;
}
.tituloNeonMateriais{
  margin-top:400px;
  font-size: 60px;
  margin-bottom: -50px;
  font-weight: 800;
  color: white;
}

.buttonMatCliente{
  background-color: #414141;
  color:#fff;
  border:none !important;
  outline: 1px solid #fff;
  width: 400px;
  box-shadow: 
  0 0 1.2rem #d5385a,
  0 0 0.8rem #d5385a,
  0 0 0.6rem #9D2727,
  0 0 0.5rem #9D2727,
  0 0 0rem #9D2727,
  inset 0 0 0 #9D2727;
  height: 40px;
  font-size: 20px;
  border-radius: 10px;
}

.avisoDrive{
  color:#fff;
  font-size: 23px;
  margin-left: 280px;
  padding-top:10px;
}

@media only screen and (min-width:2100px){
  #materiaisAdd{
    padding-top:50px;
    width: 1300px;
    margin-left:500px;
    padding-right:0px;
  }
  }

@media only screen and (min-width:2000px){
  #materiais{
    margin-left:530px;
    padding-right:0px;
  }
}
@media only screen and (max-width:1700px){
  
.edicaoMat .modal-content{
  background-color: #c8c8c8;
  width: 250px;
  margin-left: 800px;
  margin-top:200px;
  border: solid 1px gray !important;
}
#materiais{
  padding-top:50px;
  width: 1150px;
  margin-left:430px;
  padding-right:0px;
}
#materiaisAdd{
  padding-top:50px;
  width: 1300px;
  margin-left:350px;
  padding-right:0px;
}

#search_box{
  width: 100px !important;
}
.MatDrop{
  margin-left: -34px;
}
}
@media only screen and (max-width:1600px){
  .tituloNeonMateriais{
    margin-top:300px;
    margin-left: 0px;
  }
  .menuMateriais ul li{
    display: inline-block;
    margin-left:20px;
  }
  .menuMateriais ul{
    margin-left: 0px;
  }
  #materiais{
    margin-left:375px;
  }
}

@media only screen and (max-width:1536px){
  #materiais{
    padding-top:50px;
    width: 1150px;
    margin-left:330px;
    padding-right:0px;
  }
  .tituloMat{
    margin-left: 250px;
    font-size: 30px;
  }
  #materiaisAdd{
    padding-top:50px;
    width: 1050px;
    margin-left:350px;
    padding-right:0px;
  }

}

@media only screen and (max-width:1450px){
  #materiais{
    padding-top:50px;
    width: 1000px;
    margin-left:360px;
    padding-right:0px;
  }
  .tituloMat{
    margin-left: 280px;
    margin-bottom: -50px;
  }
  .edicaoMat .modal-content{
    background-color: #c8c8c8;
    width: 186px;
    margin-left: 750px;
    margin-top:200px;
    border: solid 1px gray !important;
  }

  .edicaoMat label{
    width: 150px;
    margin-left: -20px;
  }
  .MatDrop{
    width: 156px;
    font-size: 13px;
    margin-left: -34px;
  }
  .inptedit{
    width: 150px;
    margin-left: -15px;
  }
  .botaoEditMat{
    width: 155px;
    margin-left: -20px;
  }
}


@media only screen and (max-width:1366px){
  #materiais{
    padding-top:0px;
    width: 955px;
    margin-left:360px;
    padding-right:0px;
  }
  #materiaisAdd{
    margin-left:320px;
    padding-right:0px;
  }
  .tituloMat{
    font-size: 28px;
  }
}

@media only screen and (max-width:1280px){
  .menuMateriais ul{
    margin-left: -60px;
  }

.avisoDrive{
  color:#fff;
  font-size: 23px;
  margin-left: 200px;
  padding-top:10px;
}
#materiais{
  padding-top:0px;
  width: 920px;
  margin-left:360px;
  padding-right:0px;
}

#search_box{
  width: 80px !important;
}
#materiaisAdd{
width: 900px;
margin-top:-100px;
padding-top: 0px;
}
.MatDrop{
  width: 126px !important;
  font-size: 11px;
}
.edicaoMat .modal-content{
  background-color: #c8c8c8;
  width: 186px;
  margin-left: 650px;
  margin-top:200px;
  border: solid 1px gray !important;
}

}