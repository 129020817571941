.titlehistorico{
  color: #d5385a;
  font-size: 35px;
  margin-top:30px;
  margin-left:30px;
  font-weight: 600;
}
#printhistorico{
  background-image: url('../img/fundoHorizontal.png');
  background-repeat: repeat-y;
  background-size:1050px 725px !important; 
  background-position: top;
  overflow-y: hidden;
  overflow-x: hidden;
}

#printhistorico thead{
  background-color: #d5385a;
  color: #fff;
margin-top:100px;
}

#printhistorico table{
  border: 1px solid #414141;
  width: 800px;
  margin-left:160px;
  text-align: center;
  margin-top:10px !important;
}

#printhistorico table tr{
  border-bottom: 1px solid #414141;
}

#printhistorico table td{
  border-right:  1px solid #414141;
}
#printhistorico table th{
  border-right:  1px solid #414141;
}

.btnPrintMeta{
  background-color: transparent;
  height: 60px;
  position: absolute;
  top:300px;
  width: 60px;
  border-radius: 60px;
  padding: 0;
  border:none !important;
  left: 1050px;
}

.btnPrintMeta i{
  color: transparent;
 -webkit-text-stroke-width: 1px;
 font-size: 35px;
 -webkit-text-stroke-color: #fff;
 text-shadow:
 0 0 8px #d5385a,
 0 0 8px #d5385a;
}

.printM{
  border: none;
}
.timeHist {
  margin-left: 30px;
  
}
/* Create a custom checkbox */
.checkmarkH {
  position: absolute;
  top: 16px;
  left: 5px;
  height: 15px;
  width: 15px;
  background-color: #414141;
  border: 1px solid #c8c8c8;
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.containerAnalytics:hover input ~ .checkmarkH {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containerAnalytics input:checked ~ .checkmarkH {
  background-color: #d5385a;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containerAnalytics input:checked ~ .checkmarkH:after {
  display: block;
}

/* Style the checkmark/indicator */
.containerAnalytics .checkmarkH:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

#printLead{  background-image: url('../img/fundoHorizontal.png');
  background-repeat: no-repeat;
  background-size:1050px 725px !important; 
  background-position: top;
  height: 725px;
  overflow-y: hidden;
  overflow-x: hidden;
}

#PrintLeads2{
  background-image: url('../img/fundoHorizontal.png');
  background-repeat: no-repeat;
  background-size:1050px 725px !important; 
  background-position: top;
  height: 725px;
  overflow-y: hidden;
  overflow-x: hidden;
}
.tableLeadsPrint td{
  font-size: 10px;
}


.titleConsolidados{
  color: #d5385a;
  font-size: 30px;
  margin-top:30px;
  margin-left:60px;
  font-weight: 600;
}

.tableLeadsPrint thead{
  background-color:#F6315C;
  color:#fff;  
  width: 950px;
  margin-top:60px;
  height: 40px;
  text-align: center;
  margin-left: 150px;
  outline: 1px solid #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: 
  0 0 1rem #F6315C,
  0 0 0.6rem #F6315C,
  0 0 0.5rem #F6315C,
  0 0 0rem #F6315C,
  inset 0 0 0 #F6315C;
}

.backgroundLeadsPrint{
  width: 920px !important;
  margin-left: 150px;
  background-color: #ffff;
  margin-top:0px;
  color: #414141;
  max-height: 500px !important;
  overflow-x: hidden;
  overflow-y: auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.monthsEachPrint{
  padding-left: 10px !important;
  font-size: 15px;
  font-family: 'TT Norms' !important; 
  padding-top:5px;
  font-weight: 500 !important;
}

.secondColumnPrint{
  margin-left: -40px !important;
  text-align: center;
  width: 120px;
  font-size: 15px !important;
}


.grayy{
  background-color: #faf8f6;
  color: #414141;
}

.firstColumnPrint{
  width: 90px;
  font-size: 11px;
}

.tableLeadsPrint{
  width: 90%;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  }

  .tableLeadsPrint td{
    text-align: center;
    border: 1px solid #414141;
    }

  .graficoAPrint{
    margin-left: 160px;
    margin-top:10px;
  }
  .graficoBPrint{
    margin-top:-270px !important;
    margin-left: 610px;
  }

  .graficoBPrint2{
    margin-top:-310px !important;
    margin-left: 610px;
  }
  .graficoCPrint{
    margin-left: 180px;
    margin-top:10px;
  }

  .titleChartsPrint{
    text-align: center;
  }

  .titleChartsPrint2{
    text-align: center;
    margin-top:40px;
  }
  .metaFatPrint{
    background-color: #f7325a;
    width: 20px;
    height: 20px;
    border:none;
  }




  .btnfbPrint{
    background-color: #f7325a;
    width: 20px;
    height: 20px;
    border:none;
  }

  .btnGPrint{
    background-color: #fe799d;
    width: 20px;
    height: 20px;
    border:none;
  }



  .btnCPLPrint{
    background-color: #f7325a;
    width: 20px;
    height: 20px;
    border:none;
  }

  .btnCPLFbPrint{
    background-color: #fe94b6;
    width: 20px;
    height: 20px;
    border:none;
  }
  
  .btnCPLGPrint{
    background-color: #fe799d;
    width: 20px;
    height: 20px;
    border:none;
  }

#bgPrintBottom{
  background-image: url('../../assets/img/fundo3.png');
  background-repeat: no-repeat;
  background-size:100% 1600px;
  background-position: center top;
  margin-top:300px;
  font-size: 20px;
  height: 1650px;
}