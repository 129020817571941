

.inputNoticia{
  width: 1100px;
  padding-top:80px;
  overflow-y: auto ;
  overflow-x: hidden;
  margin-left: 500px;
}

.botaoNoticia{
  margin-top:30px;
  width:530px;
  margin-left: 500px;
  background-color: #d5375a;
  color:#fff;
  border:none !important;
  height: 50px;
  font-size: 20px;
}

.inpFile{
  margin-top:20px;
  margin-left:20px;
}

.titleNot{
  font-size: 25px;
  font-weight: 600;
  color: #d5375a;
}

#quadroNoticias{
  margin-left:1100px;
  margin-top:-430px;
  background-color: #fff;
  width: 600px;
  height: 440px;

  padding:20px;
  text-align: center;
  border-radius: 8px;
}
.noticiasinside::-webkit-scrollbar {
  background-color: #ededed !important;
  width: 6px;
  }
  
  .noticiasinside::-webkit-scrollbar-thumb {
  background-color: #d5375a;
  border-radius: 25px;
  height: 10px;
  }
  .noticiasinside::-webkit-scrollbar-track {
  background-color: #ededed;
  height: 80%;
  border-radius: 25px;  
  }
  
  .noticiasinside::-webkit-scrollbar-track-piece{
  background-color: #fff !important;
  }
.noticiasinside {
  max-height: calc(100vh - 630px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-top:20px;
}
#quadroNoticias ul li{
  text-align: center !important;
  width: 560px;
}
#quadroNoticias ul li b{
  padding-right: 10px;
  padding-left: 10px;
}

#quadroNoticias  a{
  color:#000;
  
}
#quadroNoticias  a:hover{
  color:#d5375a;
  font-weight: 600;
  text-decoration: none;
}

.tituloNoticias{
  font-size: 38px;
  color:#d5375a;  
  text-align: center;
  margin-left: 200px;
  padding-top:100px;
  font-weight: 600;
}

@media only screen and (min-width:2000px){
  .inputNoticia{
    margin-left: 580px;
  }
  
  .botaoNoticia{
    margin-left: 580px;
  }
  #quadroNoticias{
    margin-left:1200px;
  }
}
@media only screen and (max-width:1700px){
  
#quadroNoticias{
  width: 400px;
}
.noticiasinside {
  max-height: calc(100vh - 630px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-top:20px;
}
.tituloNoticias{
  margin-left: 240px;
}
}

@media only screen and (max-width:1600px){
.inputNoticia{
  width: 900px;
  padding-top:80px;
  overflow-y: auto ;
  overflow-x: hidden;
  margin-left: 450px;
}
.botaoNoticia{
  margin-top:30px;
  width:430px;
  margin-left: 450px;
  background-color: #d5375a;
  color:#fff;
  border:none !important;
  height: 50px;
  font-size: 20px;
}
#quadroNoticias{
  margin-left:950px;
  margin-top:-430px;
  background-color: #fff;

}
}

@media only screen and (max-width:1450px){
  .inputNoticia{
    margin-left: 400px;
    margin-top:-50px;
  }
  .botaoNoticia{
    margin-top:30px;
    width:430px;
    margin-left: 400px;
    background-color: #d5375a;
    color:#fff;
    border:none !important;
    height: 50px;
    font-size: 20px;
  }
  #quadroNoticias{
    margin-left:900px;
    height: 300px;
    margin-top:-400px;
    background-color: #fff;
  }
}

@media only screen and (max-width:1280px){
  .inputNoticia{
    width: 700px;
    margin-left: 400px;
  }
  .botaoNoticia{
    width:330px;
    margin-left: 400px;
    font-size: 17px;
  }
  #quadroNoticias{
    margin-left:800px;
    height: 300px;
    margin-top:-400px;
    background-color: #fff;
  }
}