.titleProjEdit{
  color: #d5375a;
  font-size: 38px;
  margin-left: 900px;
  font-weight: 600;
  margin-top:30px;
}


.gerenciaProjecoes{
  padding-top:50px;
  width: 1500px;
  margin-left:330px;
  padding-right:0px;
  max-height: 800px;
  border: solid 1px gray;
  overflow: auto;
}

.gerenciaProjecoes table{
  margin-left: 70px;
}
.gerenciaProjecoes table th{
  width: 200px;
} 

.gerenciaProjecoes table td{
  height: 50px;
}


.modalEditProjecao .modal-content{
  width: 1300px;
  margin-left: -300px;
  border:solid 1px gray !important;
  background-color: #c8c8c8;
  border-radius: 12px;
}

.modalEditProjecao .modal-content .closeButton{
  background-color: #c8c8c8;
  border: none !important;
}

.modalEditProjecao .modal-body{
  max-height: 800px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: 40px;
}

.modalEditProjecao .modal-body::-webkit-scrollbar {
  background-color: #ededed !important;
  width: 10px;
  }
  
  .modalEditProjecao .modal-body::-webkit-scrollbar-thumb {
  background-color: #d5375a;
  border-radius: 25px;
  height: 5px;
  }
  .modalEditProjecao .modal-body::-webkit-scrollbar-track {
  background-color: #c8c8c8;
  border: gray !important;
  height: 80%;
  border-radius: 25px;  
  }
  
  .modalEditProjecao .modal-body::-webkit-scrollbar-track-piece{
  background-color: #c8c8c8 !important;
  }

  .tituloEProj{
    font-size: 25px;
    text-align: center;
    color:#d5375a;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .btnEditProj{
    background-color: #d5375a;
    color:#fff;
    box-shadow: none !important;
    outline: none !important;
    border: none;
    height:45px;
    font-size: 20px;
    margin-left: 0px;
  }

  
  .inputProjecoesEdit3{
    color:#000;
    background-color: #fff !important;
    box-shadow: none !important;
    outline: none !important;
    border: 1px solid gray;
    width: 60px;
    text-align: center;
  }
  
  .inputProjecoesEdit{
    width: 500px;
    height: 40px;
    margin-left:100px;
    color:#000;
    background-color: #fff;
    box-shadow: none !important;
    border: solid 1px gray;
    margin-bottom:5px;
  }

  .inputUsersEdit{
    width: 500px;
    height: 40px;
    margin-left:0px;
    color:#000;
    background-color: #fff;
    box-shadow: none !important;
    border: solid 1px gray;
    outline: none !important;
    margin-bottom:5px;
  }
  
  .selectionProjecaoMesEdit select{
    width: 500px;
    height: 40px;
    margin-left:100px;
    color: gray;
    background-color: #fff;
    box-shadow: none !important;
    border: solid 1px gray;
  }

  @media only screen and (max-width:1700px){
    .gerenciaProjecoes{
      width: 1300px;
    }
    .gerenciaProjecoes table{
      margin-left: 0px !important;
    }
    .titleProjEdit{
      margin-left: 830px;
    }
    
    
  }

  @media only screen and (max-width:1600px){
    .gerenciaProjecoes{
      width: 1200px;
      margin-left: 350px;
    }
    .titleProjEdit{
      margin-left: 750px;
    }
    .modalEditProjecao .modal-content{
    height: 700px;
    }
  }

  
  @media only screen and (max-width:1540px){
    .gerenciaProjecoes{
      width: 1105px;
      margin-left: 360px;
    }
    .titleProjEdit{
      margin-left: 730px;
    }
    .modalEditProjecao .modal-content{
      margin-left: -350px;
      }
  }
  

  
  @media only screen and (max-width:1450px){
    .gerenciaProjecoes{
      width: 1005px;
      margin-left: 360px;
    }
    .gerenciaProjecoes table{
     margin-top:40px;
    }
    .titleProjEdit{
      margin-left: 700px;
    }
  }

  @media only screen and (max-width:1366px){
    .gerenciaProjecoes{
      width: 905px;
      margin-left:380px;
    }
    .titleProjEdit{
      margin-left: 670px;
    }
    .modalEditProjecao .modal-content{
      margin-left: -388px;
      }
  }

  @media only screen and (max-width:1280px){
    .gerenciaProjecoes{
      width: 855px;
      margin-left:370px;
    }
    .titleProjEdit{
      margin-left: 630px;
    }
    .modalEditProjecao .modal-content{
      margin-left: -213px;
      width: 1050px;
      max-height: 600px !important;
      }
  }