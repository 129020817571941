#printMetasBg{
  background-image: url('../img/fundoHorizontal.png');
  background-repeat: no-repeat;
  background-size:1050px 725px !important; 
  background-position: top;
  height: 725px;
  overflow-y: hidden;
  overflow-x: hidden;
}

.printDataPie{
  width: 500px;
  height:500px;
  margin-left:80px;
}

.tituloPrintMetas{
  color:  #F6315C;
  margin-left: 80px !important;
  font-size: 30px;
  margin-top:20px;
}

.printBarChart{
  width: 500px;
  height: 500px;
  margin-left: 600px;
  margin-top:-520px;
}

.totalInvestPrint{
  width: 500px;
  height: 500px;
  margin-left: 160px;
  margin-top: -180px;
}

.cplPrint{
  height: 500px;
  width: 500px;
  margin-top: -500px !important;
  margin-left: 630px;
}

.lgndCPL{
  margin-top:-30px;
  margin-left: -240px;
  text-align: center;
  font-size: 15px;
}
.lgnd{
  margin-top:-30px;
  margin-left: 20px;
}

.lgnd2{
  margin-top:0px;
  margin-left: 20px;
}
.btnInvPrint{
  background-color: #fe799d;
  width: 20px;
  height: 20px;
  border:none;
}
.btnInvPrint2{
  background-color: #f7325a;
  width: 20px;
  height: 20px;
  border:none;
}


.lgndBar{
  margin-top: -20px;
}

.lgndBar2{
  margin-top: 0px;
}

.lgndBar i{
  color:#F6315C;
}

.lgndBar2 i{
  color:#F6315C;

}

.titleInvs{
  color:#F6315C;
  font-weight: 500;
  margin-top:30px;
  margin-bottom: -60px;
}

.titleInvsBar{
  color:#F6315C;
  font-weight: 500;
  margin-top:40px;
  margin-bottom: -70px;
  margin-left:-150px;
}

.titleInvsTot{
  color:#F6315C;
  font-weight: 500;
  margin-top:30px;
  margin-bottom: -60px;
  margin-left:-170px;
  
}

.titleInvsCPL{
  color:#F6315C;
  font-weight: 500;
  margin-top:30px;
  margin-bottom: -40px;
  margin-left:-170px;
}
@media only screen and (min-width:2000px){
  .btnPrintMeta{
    top:300px;
    left: 1150px;
  }
  

}
@media only screen and (max-width:1700px){
  .btnPrintMeta{
    top:300px;
    left: 900px;
  }
  

}

@media only screen and (max-width:1600px){
  .btnPrintMeta{
    top:300px;
    left: 880px;
  }
}

@media only screen and (max-width:1536px){
  .btnPrintMeta{
    top:300px;
    left: 840px;
  }
}
@media only screen and (max-width:1440px){
  .btnPrintMeta{
    top:300px;
    left: 780px;
  }
}
@media only screen and (max-width:1366px){
  .btnPrintMeta{
    top:242px;
    left: 760px;
  }
}

@media only screen and (max-width:1280px){
  .btnPrintMeta{
    top:242px;
    left: 710px;
  }
}