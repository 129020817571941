.titleCal{
  margin-top:200px;
  font-size: 50px;
  font-weight: 800;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  text-shadow:
  0 0 10px #d5385a,
  0 0 22px #d5385a,
  0 0 32px #d5385a;
}
#calendarGrid{
  margin-top: 10px;
}
ul {list-style-type: none;}
body {font-family: 'TT Norms';}

/* Month header */
.month {
  padding: 70px 25px;
  width: 100%;
  background: #1abc9c;
  text-align: center;
}

/* Month list */
.month ul {
  margin: 0;
  padding: 0;
}

.month ul li {
  color: white;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.btnCal{
  background-color: #fff;
  border-radius: 50%;
  height: 40px;
  margin-left: 10px;
  border: none;
  width: 40px;
}
.btnCal i{
  color:#F6315C !important;
  background-color: #fff;
}


/* Previous button inside month header */
.month .prev {
  float: left;
  padding-top: 10px;
}

/* Next button */
.month .next {
  float: right;
  padding-top: 10px;
}

/* Weekdays (Mon-Sun) */
.weekdays  {
  margin: 0;
  padding: 10px 30px 0px 30px;
  background-color: #F6315C;
  margin-left: 290px;
  width: 1360px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: 
  0 0 1rem #F6315C,
  0 0 0.6rem #F6315C,
  0 0 0.5rem #F6315C,
  0 0 0rem #F6315C,
  inset 0 0 0 #F6315C;
  outline: 1px solid #fff;
}

.weekdays ul{
  width: 1360px !important;
  background-color:#414141;
  margin-left: -30px;
  height: 40px;
  padding-left: 0px;
  padding-top: 10px;
  margin-bottom: 5px;
}
.weekdays li {
  display: inline-block;
  width: 14.2%;
  margin-bottom: 0px;
  color: #fff;
  text-align: center;
  background-color:#414141;
  list-style: none !important;
  text-decoration: none !important;
}

/* Days (1-31) */
.days {
  margin: 0;
  margin-left: 0px;
  background-color: #ededed;
  width: 1500px;
  padding:5px;
}

.day{
  background-color: #414141;
  color: #fff;
  width: 190px;
  margin-top: 15px;
}

.days li {
  list-style-type: none;
  display: inline-block;
  text-align: center;
  font-size:12px;
  color: #777;
}

.day i{
  color: #fff !important;
  font-size: 12px;
  margin-left: 120px;
}


.fa-calendar-check:before{
  color:#1abc9c;
}


.fa-clock:before{
  color:yellow !important;
}

/* Highlight the "current" day */
.days li .active {
  background: #1abc9c;
  color: white !important
}

.imagem{
  max-width: 70px;
  height: 70px;
  padding-top: 10px;
  object-fit: cover;
}
.bodyCal{
  background-color: #ededed;
  margin-right: 5px;
  width: 190px;
  height:100px;
  
}

.bodyCal a{
 color:darkblue !important; 
 margin-top: 5px !important;
}
.Cal-endar{
  max-height: 450px;
  overflow-y: auto;
  width: 1360px;
  margin-left: 290px;
  overflow-x: hidden;
}

.Cal-endar::-webkit-scrollbar {
  background-color: #ededed !important;
  width: 10px;
  }
  
  .Cal-endar::-webkit-scrollbar-thumb {
  background-color: #F6315C;
  border-radius: 25px;
  width: 2px !important;
    height: 5px;
  }
  .Cal-endar::-webkit-scrollbar-track {
  background-color: #c8c8c8;
  border: gray !important;
  height: 100%;
  border-radius: 25px;  
  }
  
  .Cal-endar::-webkit-scrollbar-track-piece{
  background-color: #c8c8c8 !important;
  }

  .planLeg{
    background-color: #F6315C;
    color: #fff;
    border:none !important;
    padding:5px;
    margin-top:5px;
    margin-bottom: 5px;
    border-radius: 8px;
  }
.monthCalendar{
  color: #fff;
  font-size: 25px;
  padding-top: 0px;
  padding-bottom: 10px;
  font-weight: 600;
}
.fotoClass{
  max-width: 450px;
}
.textPlan{
  font-size: 15px;
  color: #414141;
}
.modalPostDia{
  background-color:rgba(0, 0, 0, .5) ;
}
.modalPostDia .modal-content{
  background-color: #fff;
  border: none;
  color: #414141;
  margin-top:180px;
  border-radius: 8px;
}

.modalPostDia .modal-content .closeButton{
  background-color: transparent;
  border: none;
  color:#414141;
  text-align: left;
}
.modalPostDia .modal-content .closeButton:hover{
  background-color: transparent !important;
  border: none;
  text-align: left;
}
.modalPostDia .modal-content .closeButton:focus{
  background-color: transparent !important;
  border: none;
  text-align: left;
}

.printTransparent{
  background-color: transparent;
  border: none;
}
.bodyCaloff{
  background-color: #414141;
  margin-right: 5px;
  width: 190px;
  opacity: 0.5;
  height:120px !important;
  margin-bottom: -18px;
  margin-top:10px;
}

.selectMonth{
  background-color: rgba(0, 0, 0, .5);
}

.selectMonth .modal-content{
    width: 500px;
    margin-top:350px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #ededed;
}

.selectMonth .modal-content .closeButton{
  background-color: #F6315C;
  outline:1px solid #fff;
  border:none !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: 
  0 0 1rem #F6315C,
  0 0 0.6rem #F6315C,
  0 0 0.5rem #F6315C,
  0 0 0rem #F6315C,
  inset 0 0 0 #F6315C;
}

.btnMes{
  color:#fff;
  background-color: #414141;
  border: none !important;
  padding:5px;
  width: 100px;
  margin-bottom:10px;
  border-radius:8px;
}

.upCal{
  margin-left: 290px;
  margin-top: 10px;
  color:#fff;
  font-size: 11px;

}

@media only screen and (min-width:2000px){
  /* Weekdays (Mon-Sun) */
.weekdays  {
  margin-left: 350px;
  margin-top:20px;
}
.Cal-endar{
 margin-left: 350px;
}
.upCal{
  margin-left: 370px;
}
}

@media only screen and (max-width:1700px){

  .weekdays  {
    margin-left: 170px;
    margin-top:20px;
  }
  .Cal-endar{
   margin-left: 170px;
  }
  .upCal{
    margin-left: 170px;
  }
}

@media only screen and (max-width:1600px){

  .weekdays  {
    margin-left: 120px;
    margin-top:20px;
  }
  .Cal-endar{
   margin-left: 120px;
  max-height: 350px;
  }
  .upCal{
    margin-left: 120px;
  }
}



@media only screen and (max-width:1450px){

  .weekdays  {
    margin-left: 120px;
    margin-top:20px;
    width: 1192px;
  }
  .Cal-endar{
   margin-left: -130px;
   width: 1450px;
  max-height: 300px;
  }
  
.weekdays ul{
  width: 1192px !important;
  background-color:#414141;
  text-align: center;
}
.bodyCal{
  background-color: #ededed;
  margin-right: 5px;
  width: 162px !important;
  height:100px;
  
}
.bodyCaloff{
  width: 162px;
}
.days {
  margin: 0;
  margin-left: 250px;
  width: 1192px !important;
}

.day{
  background-color: #414141;
  color: #fff;
  width: 162px;
  max-height: 20px;
}

.day i{
  color: #fff !important;
  font-size: 12px;
  margin-left: 100px !important;
}

}



@media only screen and (max-width:1370px){
  .Cal-endar{
    margin-left: -70px;
    width: 1290px;
   max-height: 250px;
   }
   
  .weekdays  {
    margin-left: 180px;
    margin-top:20px;
    width: 1040px;
  }
  .weekdays ul{
    width: 1040px !important;
    background-color:#414141;
    text-align: center;
  }
  .days {
    margin: 0;
    margin-left: 250px;
    width: 1100px !important;
  }
  .day{
    background-color: #414141;
    color: #fff;
    width: 140px;
    max-height: 20px;
  }
  .bodyCal{
    background-color: #ededed;
    margin-right: 5px;
    width: 140px !important;
    height:100px;
    
  }
  .bodyCaloff{
    width: 140px;
  }
}

@media only screen and (max-width:1280px){
  .Cal-endar{
    margin-left: -150px;
    width: 1350px;
    padding:0px;
   max-height: 250px;
   }
     
.weekdays ul{
  width: 1100px !important;
  font-size: 13px;
  background-color:#414141;
}
   .weekdays  {
    margin-left: 100px;
    margin-top:20px;
    width: 1100px !important;
  }
  .titleCal{
    margin-top:170px;
  }
  .day{
    background-color: #414141;
    color: #fff;
    width: 147px;
    max-height: 20px;
  }
  .bodyCal{
    background-color: #ededed;
    margin-right: 5px;
    width: 147px !important;
    height:100px;
    
  }
  .bodyCaloff{
    width: 147px;
  }
}