.selecione{
  margin-left:500px;
}

.edithistorico .modal-content{
 background-color: #c8c8c8;
 height: 500px;
}

.edithistorico .closeButton{
  background-color: #d5385a;
  outline: none;
  border: none;
}

.inptHistorico{
  background-color: #c8c8c8;
  box-shadow: none;
  outline: none !important;
  border: 1px solid #8c8c8c;
  width: 1350px;
  height: 40px;
  margin-bottom:10px;
}
.botaoEdithist{
  width: 450px;
  margin-top:30px;
  margin-left: 450px;
  height: 40px;
  margin-bottom:10px; 
  background-color:#d5385a;
  outline: none;
  border: none;
}

.hist{
  font-size: 25px;
  font-weight: 500;
  margin-top:50px;
  color: #d5385a;
  text-align: center;
}

.atividadeHist{
  width: 180px;
  height: 40px;
  margin-left:10px;
  margin-top:0px;
  margin-bottom: 10px;
  background-color: #d5385a !important;
  color:#fff !important;
  border: none;
  border-radius: 10px;
}


#historico{
  padding-top:50px;
  width: 1300px;
  margin-left:430px;
  padding-right:0px;
}


.tableHistorico{
  max-height: 700px;
  border: solid 1px gray;

}
.tableHistorico th{
  width: 200px;
} 

.tableHistorico td{
  height: 50px;
  width: 800px;
}
.verhistorico .modal-content{
  width: 1300px;
  margin-left: -280px;
  margin-top:100px;
  height: 500px;
  background-color: #c8c8c8;
  outline:none;
  border: none;
}

.verhistorico .modal-content .closeButton{
  background-color: #c8c8c8;
  border:none !important;
  color:#d5385a;
  font-weight: 600;
  font-size: 25px;
  text-align: left;
}

.verhistorico .modal-content .closeButton:hover{
  background-color: #c8c8c8 !important;
  color:#fff;
}

.verhistorico .modal-content .closeButton:active{
  background-color: #c8c8c8 !important;
  color:#fff;
}


.verhistorico .modal-content .closeButton i{
  font-size: 25px;
  color:#d5385a;
}
.verhistorico .modal-content .closeButton i:hover{
  color:#fff;
}
.verhistorico .modal-content .closeButton i:active{
  color:#fff;
}

.titleRosa{
  margin-top:30px;
  color:#d5385a;
  font-weight: 600;
  font-size: 40px;
  margin-left: 200px;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
background-color: #d5385a !important;
}
.calendarInput input{
  background-color: #c8c8c8;
  height: 40px;
  width: 1350px;
  border: 1px solid #8c8c8c;
}

.calendarInput input:hover{
  outline: none;
  border: none;
}

.selectHist{
  height: 40px;
  width: 1350px !important;
  background-color: #c8c8c8;
  border: 1px solid #8c8c8c;
}

.filterHist{
  width: 650px;
  height: 50px;
  margin-left: 620px;
  outline: 1px solid #fff;
  border-radius: 10px;
  color:#fff;
  font-size: 20px;
  background-color: #414141;
  box-shadow: 
  0 0 1.2rem #d5385a,
  0 0 0.8rem #d5385a,
  0 0 0.6rem #9D2727,
  0 0 0.5rem #9D2727,
  0 0 0rem #9D2727,
  inset 0 0 0 #9D2727;
}
.inputFltr input{
  height: 30px !important;
  width: 180px;
  font-size: 15px;
  text-align: center;
  margin-right: 20px;
  margin-top:10px;
  border-radius:8px;
  outline: none !important;
  border: none;
}

.txtHistoricoD{
  margin-top:10px;
  margin-left: 40px;
  margin-right: 5px;
}

.txtHistorico{
  margin-top:10px;
  margin-left: 0px;
  margin-right: 5px;
}

.littleTxt{
  font-size: 12px;
  color:#c8c8c8;
  margin-top:15px;
  width: 85px;
}

@media only screen and (min-width:2000px){
  .filterHist{
    margin-left: 680px;
    margin-top:20px;
    }
}


@media only screen and (max-width:1700px){
  .filterHist{
  margin-left: 470px;
  margin-top:20px;
  }
  #historico{
    padding-top:50px;
    width: 1150px;
    margin-left:430px;
    padding-right:0px;
  }
  .verhistorico .modal-content{
    width: 1150px;
    margin-left: -160px;
    }
    .inptHistorico{
      background-color: #c8c8c8;
      box-shadow: none;
      outline: none !important;
      border: 1px solid #8c8c8c;
      width: 1150px;
      height: 40px;
      margin-bottom:10px;
    }
    
.selectHist{
  height: 40px;
  width: 1150px !important;
  background-color: #c8c8c8;
  border: 1px solid #8c8c8c;
}
.calendarInput input{
  background-color: #c8c8c8;
  height: 40px;
  width: 1150px;
  border: 1px solid #8c8c8c;
}
.botaoEdithist{
  margin-left: 370px;
}

}
@media only screen and (max-width:1600px){
  #historico{
    padding-top:50px;
    width: 1100px;
    margin-left:400px;
    padding-right:0px;
  }
  .inptHistorico{
    background-color: #c8c8c8;
    box-shadow: none;
    outline: none !important;
    border: 1px solid #8c8c8c;
    width: 1050px;
    height: 40px;
    margin-bottom:10px;
  }
  
.selectHist{
height: 40px;
width: 1050px !important;
background-color: #c8c8c8;
border: 1px solid #8c8c8c;
}
.calendarInput input{
background-color: #c8c8c8;
height: 40px;
width: 1050px;
border: 1px solid #8c8c8c;
}
.botaoEdithist{
margin-left: 370px;
}
.titleRosa{
  font-size: 40px;
  margin-left: 280px;
}
}

@media only screen and (max-width:1550px){
  .filterHist{
    margin-left: 420px;
  }
  #historico{
    padding-top:50px;
    width: 1100px;
    margin-left:360px;
    padding-right:0px;
  }
  .botaoEdithist{
    margin-left: 300px;
  }
  .inptHistorico{
    background-color: #c8c8c8;
    box-shadow: none;
    outline: none !important;
    border: 1px solid #8c8c8c;
    width: 950px;
    height: 40px;
    margin-bottom:10px;
  }
  .calendarInput input{
    background-color: #c8c8c8;
    height: 40px;
    width: 950px;
    border: 1px solid #8c8c8c;
    }
    .selectHist{
      height: 40px;
      width: 950px !important;
      background-color: #c8c8c8;
      border: 1px solid #8c8c8c;
    }
}


@media only screen and (max-width:1450px){
  .filterHist{
    margin-left: 380px;
  }
  #historico{
    padding-top:50px;
    width: 1000px;
    margin-left:360px;
    padding-right:0px;
  }
  .verhistorico .modal-content{
    width: 950px;
    margin-left: -80px;
   
    }
    .titleRosa{
      font-size: 30px;
      margin-left: 280px;
    }
    .inptHistorico{
      width: 850px !important;
      height: 40px;
      margin-bottom:10px;
    }
    .calendarInput input{
      background-color: #c8c8c8;
      height: 40px;
      width: 850px;
      border: 1px solid #8c8c8c;
      }
      
.selectHist{
  height: 40px;
  width: 850px !important;
  background-color: #c8c8c8;
  border: 1px solid #8c8c8c;
}
.botaoEdithist{
  margin-left: 220px;
}
}



@media only screen and (max-width:1370px){
  .filterHist{
    margin-left: 340px;
  }
  #historico{
    padding-top:50px;
    width: 900px;
    margin-left:400px;
    padding-right:0px;
  }
  .titleRosa{
    margin-left: 300px;
  }
  .verhistorico .modal-content{
    width: 850px;
    margin-left: -20px;
   
    }
    .verhistorico .modal-content .closeButton{
      font-size: 20px;
     
      }
      .inptHistorico{
        width: 750px !important;
        height: 40px;
        margin-bottom:10px;
      }
      .calendarInput input{
        background-color: #c8c8c8;
        height: 40px;
        width: 750px;
        border: 1px solid #8c8c8c;
        }
        
  .selectHist{
    height: 40px;
    width: 750px !important;
    background-color: #c8c8c8;
    border: 1px solid #8c8c8c;
  }
  .botaoEdithist{
    margin-left: 160px;
  }
}



@media only screen and (max-width:1280px){
  .filterHist{
    margin-left: 300px;
  }
  #historico{
    padding-top:50px;
    width: 800px;
    margin-left:400px;
    padding-right:0px;
  }
  .titleRosa{
    margin-left: 300px;
    font-size: 25px;
  }
}