#notas{
  padding-top:50px;
  width: 1300px;
  margin-left:430px;
  padding-right:0px;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0px;
  left: 30px !important;
  height: 15px;
  width: 15px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.containerNotas:hover input {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containerNotas input:checked {
  background-color: #F6315C;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containerNotas input:checked:after {
  display: block;
}

/* Style the checkmark/indicator */
.containerNotas .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.DropNotas{
  background-color: #F6315C;
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
  width: 200px;
  margin-left: 800px;
  margin-top:10px;
}

.dropNotes {
  width: 200px;
}


.inptCalendar{
  text-align: center;
  width: 90%;
  margin-left: 8px;
}
.containerNotas{
  margin-left: 22px;
  margin-top:10px;
  width: 300px;
}
.tableNotas{
  max-height: 900px;
  border: solid 1px gray;
  margin-left: 400px;
  width: 1050px;
  margin-top:50px;
}




.tableNotas td{
  padding-top:10px !important; 
}
.archivebtn{
  background-color: #F6315C;
  color:#fff;
  border-radius: 8px;
  border: none !important;
  height: 30px;
  margin-left: 10px;
}

.editbtn{
  background-color: #F1DEE6;
  height: 30px;
}

.btnNota{
  border: none !important;
  background-color: transparent;
}

.btnNota i{
  color:#F6315C;
  font-size: 50px;
  margin-left: -40px;
}

.inpt{
  margin-left: -30px;
}

.edicaoNota .modal-content{
  background-color: #c8c8c8;
  
}

.edicaoNota .modal-content .closeButton{
  background-color: #c8c8c8;
  border: none;
  box-shadow: none;
}

.nameNota{
  font-size: 15px;
}

.empresa{
  color:#D5375A;
  font-size: 25px;
  font-weight: 500;
  margin-top:50px;
  margin-bottom:-30px;
  margin-left:-30px;
}

.alterar{
  background-color:transparent;
  color:#fff;
  border:none !important;
  border-radius: 8px;
  height: 40px;
  font-size: 20px;
}
.titleRosaNotas{
  font-size: 45px;
  color: #D5375A;
  font-weight: 500;
}

.changeDados .modal-content{
  background-color: #c8c8c8;
}

.changeDados .modal-content ul{
  list-style: none;
}
.changeDados .modal-content .botaoChangeDados{
  background-color: #D5375A;
  width: 90%;
  height: 40px;
  margin-top:10px;
  border: none ;
  color: #fff;
  font-weight: 500;
  border-radius: 8px;

}
#search_boxNotas {
  margin-top:50px;
}
.titleNotas{
  font-size: 55px;
  margin-top:130px;
  margin-bottom: 0px;
  font-weight: 800;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  text-shadow:
  0 0 10px #d5385a,
  0 0 22px #d5385a;
}

.ultimo_acesso{
  color: #fff;
  margin-bottom: 30px !important;
  margin-left: 50px;
  font-size: 14px;

}
.notasChoice{
  width: 650px;
  height: 35px;
  outline: 1px solid #fff;
  border-radius: 10px;
  color:#fff;
  margin-top:30px;
  font-size: 20px;
  background-color: #414141;
  box-shadow: 
  0 0 1.2rem #d5385a,
  0 0 0.8rem #d5385a,
  0 0 0.6rem #9D2727,
  0 0 0.5rem #9D2727,
  0 0 0rem #9D2727,
  inset 0 0 0 #9D2727;
}

.notasChoice ul{
  list-style: none;
}

.notasChoice ul li{
  display: inline-block;
  padding-right: 20px;
}

.notasChoice label{
  font-size: 12px;
  color:#c8c8c8;
}

.notasChoice span{
  margin-top: -16px;
}

.btnDownloadNotas{
  margin-top:80px;
}

.notaLista ul{
  list-style: none;
  margin-top:20px;
}

.notaLista ul li{
  
  border-bottom:solid 2px #fff;
  height: 40px;
  width: 590px;
  padding-top:10px;
}
.notaLista ul li a{
  color:#000000;
}

.notasDoMes .modal-content{    
  width: 690px !important;
  background-color: #434242;
  color:#fff;
  margin-top: 250px;
  height: 500px;
  box-shadow: 
  0 0 12px #d5385a,
  0 0 22px #d5385a;
  margin-left: -100px;
}

.notasDoMes  .modal-body{
  max-height: 400px !important;
  overflow-x: auto;
  max-width: 98%;
}
.notasDoMes .modal-content a{    
  color:#fff;
}


.notasDoMes  .modal-body::-webkit-scrollbar {
  background-color: #434242 !important;
  width: 10px;
  }
  
  .notasDoMes  .modal-body::-webkit-scrollbar-thumb {
  background-color: #d5385a;
  border-radius: 25px;
  height: 5px;
  }
 .notasDoMes  .modal-body::-webkit-scrollbar-track {
  background-color: #434242;
  border: gray !important;
  height: 80%;
  border-radius: 25px;  
  }
  
 .notasDoMes .modal-body::-webkit-scrollbar-track-piece{
  background-color: #434242 !important;
  }

.notasDoMes .modal-content .closeButton{
  background-color: #434242;
  border:none !important;
  box-shadow: none !important;
}

.titleNota{
  font-size: 25px;
  text-align: center;
  color: #d5385a;
  font-weight: 500;
}

.minip{
  text-align: center;
  font-size: 14px;
}



.notasDoMesEspec .modal-content{    
  width: 690px !important;
  background-color: #434242;
  color:#fff;
  margin-top: 250px;
  height: 250px;
  box-shadow: 
  0 0 12px #d5385a,
  0 0 22px #d5385a;
  margin-left: -100px;
}


.notasDoMesEspec .modal-content a{    
  color:#fff;
}


.notasDoMesEspec .modal-content .closeButton{
  background-color: #434242;
  border:none !important;
  box-shadow: none !important;
}


@media only screen and (max-width:1700px){
.DropNotas{
  margin-left: -30px;
  width: 200px;
  
}
#notas p{
  margin-left: -300px;
  margin-right:150px;
}
.tableNotas{
  max-height: 900px;
  border: solid 1px gray;
  margin-left: 370px;
  width: 950px;
  margin-top:50px;
}

.titleRosaNotas{
  font-size: 40px;
  margin-left:-200px;
}



}