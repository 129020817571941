.backgroundLeads{
  width: 1700px !important;
  margin-left: 80px;
  background-color: #faf8f6 !important;
  margin-top:0px;
  color: #414141 !important;
  width: 1700px;
  max-height: 500px !important;
  overflow-x: hidden;
  overflow-y: auto;
}

.backgroundLeads::-webkit-scrollbar {
  background-color: #ededed !important;
  width: 5px;
  }
  
  .backgroundLeads::-webkit-scrollbar-thumb {
  background-color: #F6315C;
  border-radius: 25px;
  height: 5px;
  }
  .backgroundLeads::-webkit-scrollbar-track {
  background-color: #c8c8c8;
  border: gray !important;
  height: 80%;
  border-radius: 25px;  
  }
  
  .backgroundLeads::-webkit-scrollbar-track-piece{
  background-color: #c8c8c8 !important;
  }

.resultTitulo{
  margin-top:200px;
  font-size: 50px;
  font-weight: 800;
  color: white;
}

.neonTr{
  background-color:#F6315C;
  color:#fff;  
  width: 1700px;
  margin-top:20px;
  height: 40px;
  margin-left: 80px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.months{
  margin-left: 230px !important;
  margin-top:-30px;
}

.monthsEach{
  padding-left: 60px;
  font-size: 17px;
  font-family: 'TT Norms' !important; 
  padding-top:5px;
  font-weight: 300 !important;
}

.tableLeads{
width: 100%;
font-size: 14px;
}


.secondColumn{
  margin-left: -40px !important;
  text-align: center;
  width: 150px;
  font-size: 13px !important;
}


.grayy{
  background-color: #a3989a;
  color: #fff;
}

.firstColumn{
  width: 280px;
  font-size: 14px;
}
.col1{
  margin-left: -30px !important;
  text-align: center;
  font-size: 13px !important;
  width: 150px;
}

.col2{
  margin-left: -30px !important;
  text-align: center;
  width: 150px;
  font-size: 13px !important;
}

.col3{
  margin-left: -45px !important;
  text-align: center;
  font-size: 13px !important;
  width: 150px;
}
.col4{
  margin-left: -60px !important;
  text-align: center;
  width: 150px;
  font-size: 13px !important;
}
.col5{
  margin-left: -50px !important;
  text-align: center;
  width: 150px;
  font-size: 13px !important;
}
.col6{
  margin-left: -50px !important;
  text-align: center;
  width: 150px;
  font-size: 13px !important;
}
.col7{
  margin-left: -40px !important;
  text-align: center;
  width: 150px;
  font-size: 13px !important;
}
.col8{
  margin-left: -30px !important;
  text-align: center;
  width: 150px;
  font-size: 13px !important;
}
.col9{
  margin-left: -20px !important;
  text-align: center;
  width: 150px;
  font-size: 13px !important;
}
.col10{
  margin-left: -20px !important;
  text-align: center;
  width: 150px;
  font-size: 13px !important;
}
.col11{
  margin-left: -20px !important;
  text-align: center;
  width: 150px;
  font-size: 13px !important;
}

.titleChartLeads{
  font-size: 22px;
  margin-top:50px;
  margin-left: 100px;
  text-align: center;
}

.anotherFont{
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #414141;
  font-weight: 800;
}


.anotherFont:hover{
  color: #414141 !important;
  text-decoration: none !important;
}

.graficoC{
  margin-left: 400px;
}


.titleChartLeadsLast{
  font-size: 22px;
  margin-top:50px;
  margin-left: -400px;
  text-align: center;
}

.lineshadowLeads{
  filter: drop-shadow(6px 4px 4px #d5385a);
}
@media only screen and (max-width:1700px){
  .neonTr{
    width: 1520px;
  }
  .monthsEach{
    padding-left: 48px;
  }
  .backgroundLeads{
    width: 1520px !important;
    margin-left: 80px;
    background-color: #a3989a;
    margin-top:0px;
    color: #faf8f6;

    max-height: 400px !important;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
}

@media only screen and (max-width:1600px){
  .backgroundLeads{
    width: 1350px !important;
    margin-left: 80px;
    background-color: #a3989a;
    margin-top:0px;
    color: #faf8f6;
    max-height: 350px !important;
  
  }

  .neonTr{
    width: 1350px;
  }

  .monthsEach{
    padding-left: 32px;
  }
  
.firstColumn{
  width: 220px;
  font-size: 14px;
}
.secondColumn{
  margin-left: 0px !important;
  font-size: 12px;
  width: 120px;
}
.col1{
  margin-left:-30px !important;
  text-align: center;
  font-size: 12px !important;
  width: 120px;
}

.col2{
  margin-left: -20px !important;
  text-align: center;
  width: 120px;
  font-size: 12px !important;
}

.col3{
  margin-left: -45px !important;
  text-align: center;
  font-size: 12px !important;
  width: 120px;
}
.col4{
  margin-left: -50px !important;
  text-align: center;
  width: 120px;
  font-size: 12px !important;
}
.col5{
  margin-left: -50px !important;
  text-align: center;
  width: 120px;
  font-size: 12px !important;
}

.col6{
  margin-left: -40px !important;
  text-align: center;
  width: 120px;
  font-size: 12px !important;
}
.col7{
  margin-left: -40px !important;
  text-align: center;
  width: 120px;
  font-size: 12px !important;
}
.col8{
  margin-left: -30px !important;
  text-align: center;
  width: 120px;
  font-size: 12px !important;
}
.col9{
  margin-left: -20px !important;
  text-align: center;
  width: 120px;
  font-size: 12px !important;
}
.col10{
  margin-left: -20px !important;
  text-align: center;
  width: 120px;
  font-size: 12px !important;
}
.col11{
  margin-left: -20px !important;
  text-align: center;
  width: 120px;
  font-size: 12px !important;
}

.chartA svg{
  width: 600px;
  margin-top:-50px;
}
.chartB svg{
  width: 600px;
  margin-top:-50px;

}
.chartC svg{
  width: 600px;
  margin-top:-50px;
}
.chartD svg{
  width: 600px;
  margin-top:-50px;

}
.chartE svg{
  width: 700px;
  margin-top:-40px;
  margin-left: -100px;
}

}


@media only screen and (max-width:1370px){
  .backgroundLeads{
    width: 1150px !important;
    margin-left: 80px;
    background-color: #a3989a;
    margin-top:0px;
    color: #faf8f6;
    max-height: 300px !important;
  
  }

  .neonTr{
    width: 1150px;
  }

  .monthsEach{
    padding-left: 35px;
    font-size: 13px;
  }
  .firstColumn{
    width: 180px;
    font-size: 14px;
  }
  .months{
    margin-left: 170px !important;
  }
  .secondColumn{
    margin-left: -20px !important;
  }
  .col1{
    margin-left:-40px !important;
  }
  .col2{
    margin-left: -30px !important;

  }
  .col3{
    margin-left: -50px !important;
  }
  .col4{
    margin-left: -60px !important;
  }
  .col5{
    margin-left: -60px !important;
  }
  .col6{
    margin-left: -50px !important;

  }
  .col7{
    margin-left: -50px !important;
  }
  .col8{
    margin-left: -40px !important;
  }
  .col9{
    margin-left: -30px !important;
  }
  .col10{
    margin-left: -20px !important;
    text-align: center;
    width: 120px;
    font-size: 12px !important;
  }
  .col11{
    margin-left: -30px !important;
    text-align: center;
    width: 120px;
    font-size: 12px !important;
  }
  
.chartA svg{
  width: 500px;
  margin-top:-50px;
  margin-left: 40px;
}
.chartB svg{
  width: 500px;
  margin-top:-50px;
  margin-left: 40px;

}
.chartC svg{
  width: 500px;
  margin-top:-50px;
  margin-left: 40px;
}
.chartD svg{
  width: 500px;
  margin-top:-50px;
  margin-left: 40px;

}
.chartE svg{
  width: 700px;
  margin-top:-40px;
  margin-left: -170px;
}

}