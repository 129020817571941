#realTime{
  width: 100% !important;
  height: 100vh;
  max-height: 100%;
  overflow-y: hidden;
  /*background-image: url("../img/bg2.png");*/
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.choices{
  margin-top:400px;
}

.choices ul li{
  display: inline-block !important;
  padding: 30px;
}

.buttonTR{
  height: 200px;
  width: 200px;
  background-color: #ededed;
  border-radius: 50%;
  border:none !important;
  color:#EF232C;
  font-weight: 600;


}

.lgndChart{
  color:#414141;
  position: absolute;
  top: 400px;
  font-size:20px;
}

.lds{
  height: 30px;
  width: 150px;
  border-radius: 8px;
  color:#fff;
  border: none !important;
  background-color: #F6315C;
}

.lds:hover{
  background-color: #414141;
  color:#fff;
}

.vlr{
  height: 30px;
  width: 150px;
  color:#fff;
  border-radius: 8px;
  border: none !important;
  background-color: #414141;
}

.vlr:hover{
  background-color: #F6315C;
  color:#fff;
}

.infos{
  box-shadow: 
              0 0 0.6rem #9D2727,
              0 0 0.5rem #9D2727,
              0 0 0rem #9D2727,
              inset 0 0 0 #9D2727;
font-weight: 500;
color: #414141;
width: 250px;
margin-left: 700px;
margin-top: 20px;
}


.buttonTR i{
  font-size: 80px;
  color: black;
  padding-top:8px;
}

.custom-tooltip{
  background-color: #ededed;
  color:#414141 !important;
  padding:10px !important;
  border-radius: 10px;
  font-weight: 400 !important;
  box-shadow: 
  0 0 0.8rem #d5385a,
  0 0 0.6rem #d5385a,
  0 0 0.5rem #9D2727,
  0 0 0.4rem #9D2727,
  0 0 0rem #9D2727,
  inset 0 0 0 #9D2727;
}

.back{
  margin-top:160px;
  margin-left:30px;
  letter-spacing: 3px;
  z-index: 9999;
  color: #fff;
  background-color: transparent;
  border: none !important;
  outline:  none !important;
  font-size: 25px;
}


.fbAdsTitle{
  font-size: 60px;
  margin-top:-80px;
  margin-bottom: -50px;
  font-weight: 800;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  text-shadow:
  0 0 10px #d5385a,
  0 0 22px #d5385a;

}

.analyticsTitle{
  font-size: 60px;
  margin-top:-30px;
  margin-bottom: -50px;
  font-weight: 800;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  text-shadow:
  0 0 10px #d5385a,
  0 0 22px #d5385a;

}

.hd{
  max-width: 220px;
}

.blackbackground{
  width: 1000px;
  height: 500px !important;
  background-color: #ededed;
}
#leadchart svg{
   margin-top: -60px;
   margin-left: 0px;
   margin-bottom: 0px;
 }
 
.lineshadow{  filter: drop-shadow(6px 4px 4px #d5385a);}

.up{
  color: #fff;
  margin-top:-30px;
  margin-left: 5px;
  font-size: 12px;
  text-shadow: none !important;
}

.boxAnalyticsG{
  background-color: #fff;
  height: 350px;
  width: 310px;
  display: inline-block;
  padding:0px;
  margin-left: 10px;
  text-align: center;
}


.bgAnalytics{
  width: 95%;
  margin-left: 120px;
  margin-top:110px;
}

.metodo{
  max-width: 200px;
  height: auto;
  margin-left: -150px;
}
.upAnalytics{
  margin-left: -780px;
  margin-top:20px;
  font-size: 12px;
  color: #fff;
  text-shadow:none !important;
}

@media only screen and (max-width:1700px){
  .upAnalytics{
    margin-left: -680px;
    margin-top:20px;
    font-size: 12px;
    color: #fff;
    text-shadow:
    0 0 10px #000,
    0 0 22px #000;
  }
  
}
@media only screen and (max-width:1600px){
  .choices{
    margin-top:350px;
  }
  .blackbackground{
    width: 1000px;
    height: 460px !important;
    background-color: #ededed;
  }
  
#leadchart svg{
 height: 90%;
  margin-top: 30px;
  margin-left: 100px;
  margin-bottom: 0px;
}

.lgndd{
  margin-top:-200px !important;
}
.up{
  margin-top:-65px;
  margin-left: 5px;
  font-size: 12px;

}
.upAnalytics{
  margin-left: -630px;
}

}

@media only screen and (max-width:1370px){

  .buttonTR{
    height: 170px;
    width: 170px;  
  }
  .choices{
    margin-top:300px;
  }
  .blackbackground{
    width: 800px;
    margin-top:-10px;
    height: 360px !important;
    background-color: #ededed;
  }
  
.boxAnalyticsG{
  background-color: #fff;
  height: 220px;
  width: auto;
  padding:0px;
}
.upAnalytics{
  margin-left: -530px;
}

.metodo{
  max-width: 150px;
  margin-top:-20px;
}

#leadchart svg{
  height: 90%;
  margin-bottom: 180px;
  padding-top: 0 !important;
  margin-left: 140px;

 }
 .infos{
margin-left: 500px;
margin-top: 20px;
}

 .up{
  color: #fff;
  margin-top:-165px;

}
  }
  @media only screen and (max-width:1280px){
  
    .buttonTR{
      height: 160px;
      width: 160px;  
    }
  
    .blackbackground{
      width: 800px;
      margin-top:-10px;
      height: 300px !important;
      background-color: #ededed;
    }
      
  #leadchart svg{
    height: 360px;
    margin-top:50px;
    margin-bottom:0px;
  }
  .up{
    margin-top:-220px;
    margin-left: 5px;
    font-size: 12px;
   
  }

  .upAnalytics{
    margin-left: -490px;
  }
    }
