#registrar{ 
  margin-left: 360px;
  border: 1px solid gray !important;
  width: 1500px;
  padding:50px;
  margin-top:20px;
  max-height: 800px;
  overflow-y:auto ;
  overflow-x:hidden ;
}

.tituloUsuario{
  font-size: 38px;
  margin-left:900px;
  margin-top:40px;
  font-weight: 600;
  color: #EF232C;
}

.inputUser{
  width: 1000px;
  height: 50px;
  margin-top:10px;
  background-color: #c8c8c8;
  box-shadow: none !important;
  border: solid 1px gray;
}

.inputUser:hover{
  border:none !important;
  outline: none !important;
  box-shadow: none !important;
}

.inputUser:focus{
  border:none !important;
  outline: none !important;
  box-shadow: none !important;
}


.selectionRole{
  width: 1000px;
  height: 50px;
  margin-top:10px;
  margin-left:00px;
  background-color: #c8c8c8;
  box-shadow: none !important;
  border: solid 1px gray;
}

.campos{
  margin-left:80px !important;
}

.campos label{
  margin-top:10px;

}

.checkUser{
  margin-left:15px;
  margin-top:0px;
  padding:0;
}

.btnEnviarUser{
  background-color: #EF232C;
  color:#fff;
  box-shadow: none !important;
  outline: none !important;
  border: none;
  height:45px;
  font-size: 20px;
  margin-top: 20px;
}


/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #d5375a;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
@media only screen and (min-width:2000px){
  #registrar{ 
    margin-left: 360px;
  }
  .tituloUsuario{
    margin-left:960px;
  }
}
@media only screen and (max-width:1700px){
#registrar{ 
  width: 1250px;
}

.inputUser{
  width: 830px;

}

.selectionRole{
  width: 830px;
}


}
@media only screen and (max-width:1600px){
  #registrar{ 
    width: 1200px;
    height: 700px;
  }
  
  .inputUser{
    width: 830px;
  
  }
  
  .selectionRole{
    width: 830px;
  }
  
  
.tituloUsuario{
  font-size: 38px;
  margin-left:800px;
  margin-top:40px;
  font-weight: 600;
  color: #d5375a;
}
.btnEnviarUser{
  height:35px;
  font-size: 18px; 
  padding-top:0px;
  padding-bottom: 0px;
}

  }
  @media only screen and (max-width:1570px){
    #registrar{ 
      margin-left: 310px;
    
    }
    .tituloUsuario{
      font-size: 38px;
      margin-left:760px;
      margin-top:40px;
      font-weight: 600;
      color: #d5375a;
    }
  }

  @media only screen and (max-width:1450px){
    #registrar{ 
      width: 1100px;
      height: 700px;
    }
    .campos label{
      margin-left:-100px;
    }
    .inputUser{
      width: 630px;
    }
    .selectionRole{
      width: 630px;
    }
    .tituloUsuario{
      margin-left:740px;
    }
    .listaPermissoes{
     width: 100% !important;
    }
    .containerEquipe{
      width: 250px;
    }
  }
  
  @media only screen and (max-width:1380px){
    #registrar{ 
      width: 900px;
      height: 600px;
      margin-left:400px;
    }
    .containerEquipe{
      width: 200px;
    }
    
      .containerEquipe [type=checkbox] {
        width: 1.3rem;
        height:  1.3rem;
      }
      .inputUser{
        width: 530px;
        height: 40px;
        
      }
      .selectionRole{
        width: 530px;
        height: 40px;
      }
      .campos{
        margin-top:-20px;
      }
      .tituloUsuario{
        font-size: 30px;
        margin-left:750px;
      
      }
  }
  
  @media only screen and (max-width:1280px){
    #registrar{ 
      margin-left:340px;
      height: 590px;
    }
    .containerEquipe [type=checkbox] {
      margin-left:5px;
    }
    .tituloUsuario{
      font-size: 30px;
      margin-left:660px;
    
    }
  }