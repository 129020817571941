.fbInput{
  font-size: 15px;
}

.selectionFb select{
  width: 300px;
  height: 40px;
  margin-left:-60px;
  color: gray;
  background-color: #ededed;
  box-shadow: none !important;
  border: solid 1px gray;
}

.selectionFb select:hover{
  background-color: gray;
  color: #fff;
}

.selectionFb select:focus{
  background-color: #fff !important;
  color: gray;
}

.edicaoFb .modal-content{
  width: 1400px !important;
  margin-left: -300px;
  height: 800px;
  background-color: #c8c8c8;
}

.edicaoFb .modal-body{
  max-height: 700px;
  overflow: auto;
}

.edicaoFb .modal-content .closeButton{
  background-color: rgb(212,54,90);
  border: none !important;
}

.edicaoFb .modal-body::-webkit-scrollbar {
  background-color: #ededed !important;
  width: 10px;
  }
  
  .edicaoFb .modal-body::-webkit-scrollbar-thumb {
  background-color: #F6315C;
  border-radius: 25px;
  width: 2px !important;
    height: 5px;
  }
  .edicaoFb .modal-body::-webkit-scrollbar-track {
  background-color: #c8c8c8;
  border: gray !important;
  height: 100%;
  border-radius: 25px;  
  }
  
  .edicaoFb .modal-body::-webkit-scrollbar-track-piece{
  background-color: #c8c8c8 !important;
  }


#facebookAdd{
  padding-top:0px;
  width: 1300px;
  margin-left:430px;
  padding-right:0px;
  border: solid gray 1px;
}

.tableFacebooks{
  width: 1300px;
}

.tableFacebooks td{
  width: 300px;
}
.fbBtn{
  margin-left: 100px;
  background-color: #FE799D;
  border:none !important;
  color:#fff;
  padding:8px;
  width: 80px;
  border-radius: 5px;
}

.dropFb{
  width: 400px;
  background-color: rgb(212,54,90);
  border: none !important;
  margin-bottom: 20px;
  margin-top:50px;
  margin-left:400px;
}

.dropFbhover{
  width: 400px;
}

.vl{
  margin-top:70px;
  margin-bottom:30px;
}

.inputProjecoes3{
  text-align: center;
  width: 100px;
  color:#000;
}

.empresafb{
  margin-left: 430px;
  margin-bottom: 20px;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
}

.alterarfb{
  color:#F6315C;
  font-weight: 500;
  background-color: transparent;
  border: none !important;
}

.inputAnalytics{
  background-color: #c8c8c8;
  border: 1px solid gray;
}

.botaoAnalytics{
  margin-top: 20px;
  border:none !important;
  width: 100px;
  background-color: #D5375A;
}

.edicaoAnalytics .modal-content{
  width: 500px !important;
  margin-left: 50px;
  margin-top: 100px;
  height: 500px;
  background-color: #c8c8c8;
}


.edicaoAnalytics .modal-content .closeButton{
  background-color: rgb(212,54,90);
  border: none !important;
}
