
@font-face {
  font-family: 'TT Norms';
  src: url('../fonts/TTNorms-Regular.woff2') format('woff2'),
      url('../fonts/TTNorms-Regular.woff') format('woff'),
      url('../fonts/TTNorms-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  font-variant-ligatures: none;
}

body{
  font-family: 'TT Norms' !important;
  font-display: swap !important;
  background-color: #202127;
  display:flex;
  flex-direction:column; 
  margin-bottom: 0px;
  font-variant-ligatures: none;
  color: white;
}

.LoginAll{
  background-image: url("../img/bg2.png");
  background-size:100% 100%;
  width: 100% !important;
  height: 100vh;
  max-height: 100%;
  overflow-y: hidden;
  background-repeat: no-repeat;
}
  .a:after{
    display: block;
    left: 0;
    bottom: -10px;
    width: 0;
    height: 2px;
    background-color: #b20000;
    content: "";
    transition: width 0.2s;
  }
  .fa{
    color:#fff;
    font-size:25px;
  }

  #navbar{
    background-color:#414141;
   opacity: 1 !important;
  }
  #navbar nav{
    display:flex;
    justify-content:space-between;
    align-items:center;
    background-color:#414141;
    padding: .8rem 0
  }
  #navbar nav .logo-link img{
    width:140px;

  }
  #navbar nav .text-link{
    padding: 0 30px 0 20px;
    font-size:19px;
    width: auto;
    color:#ededed;
    text-decoration: none;
    font-weight: 400;
    text-align: center;
  }

  #navbar nav .text-link:hover{
   color: #F6315C;
   border-bottom: 2px solid #F6315C;
   font-weight: 500;
  }

  #navbar nav .text-link.active{
    color:#F6315C;
    font-weight:500;
   
  }
  #navbar nav .log{
    padding: 0 50px;
    font-size:19px;
    color:#F6315C !important;
    text-decoration: none;
    font-weight: 500;
  }


nav.navbarLog{
  background-color: #414141 !important;
}

.whiteBgLogin{
  background-color: #ededed;
  padding-top:20px;
  padding-bottom:20px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 8px;
}

.LoginForm{
  margin-top:5rem;
  margin-left: 660px;

  
  .field-icon {
    float: right;
    margin-left: -25px;
    margin-top: -25px;
    position: relative;
    z-index: 2;
  }

  /* BASIC */

  html {
    background-color: #F6315C;
  }

  body {
    font-family: 'TT Norms' !important;
    font-display: swap !important;
    height: 100vh;
  }

  a {
    color: #92badd;
    display:inline-block;
    text-decoration: none;
    font-weight: 400;
  }

  h2 {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    display:inline-block;
    margin: 40px 8px 10px 8px;
    color: #cccccc;
  }


  img{
    width: 100%;
  }

  /* STRUCTURE */

  .wrapper {
    height: 91.9vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 100%;
    padding: 0px;
  }

  #formContent {
    border-radius: 10px 10px 10px 10px;
    max-width: 450px;
    position: relative;
    text-align: center;
  }



  #formFooter {
    font-family: 'TT Norms' !important;
    font-display: swap !important;


  }

  #formFooter .azul a{ 
   color:#F6315C;
  }
  /* TABS */

  h2.inactive {
    color: #cccccc;
  }

  h2.active {
    color: #0d0d0d;
    border-bottom: 2px solid #F6315C;
  }

  /* FORM TYPOGRAPHY*/

  input[type=button], input[type=submit], input[type=reset]  {
    background-color: #F6315C;
    border: none;
    color: white;
    padding: 19px 199px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    text-transform: uppercase;
    font-size: 13px;
    border-radius: 3px;
    margin: 5px 20px 40px 5px;
    transition: all 0.3s ease-in-out;
  }

  input[type=button]:hover, input[type=submit]:hover, input[type=reset]:hover  {
    background-color: #F6315C;
  }

  input[type=button]:active, input[type=submit]:active, input[type=reset]:active  {
    -moz-transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -o-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);
  }

  input[type=text] {
    background-color: #f6f6f6;
    color: #0d0d0d;
    padding: 15px 32px;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 5px;
    width: 100%;
    height: 70px;
    border: 2px solid #f6f6f6;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
  }
  input[type=password] {
    background-color: #f6f6f6;
    color: #0d0d0d;
    padding: 15px 32px;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 5px;
    width: 100%;
    height: 70px;
    border: 2px solid #f6f6f6;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
  }

  input[type=text]:focus {
    background-color: #fff;
    border-bottom: 2px solid #F6315C;
  }

  input[type=text]::placeholder {
    color: #cccccc;
  }



  /* ANIMATIONS */

  /* Simple CSS3 Fade-in-down Animation */
  .fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  @-webkit-keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }

  @keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }

  /* Simple CSS3 Fade-in Animation */
  @-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
  @-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
  @keyframes fadeIn { from { opacity:0; } to { opacity:1; } }

  .fadeIn {
    opacity:0;
    -webkit-animation:fadeIn ease-in 1;
    -moz-animation:fadeIn ease-in 1;
    animation:fadeIn ease-in 1;

    -webkit-animation-fill-mode:forwards;
    -moz-animation-fill-mode:forwards;
    animation-fill-mode:forwards;

    -webkit-animation-duration:1s;
    -moz-animation-duration:1s;
    animation-duration:1s;
  }

  .fadeIn.first {
    -webkit-animation-delay: 0.4s;
    -moz-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }

  .fadeIn.second {
    -webkit-animation-delay: 0.6s;
    -moz-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }

  .fadeIn.third {
    -webkit-animation-delay: 0.8s;
    -moz-animation-delay: 0.8s;
    animation-delay: 0.8s;
  }

  .fadeIn.fourth {
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    animation-delay: 1s;
  }

  /* Simple CSS3 Fade-in Animation */
  .underlineHover{
    color: #414141;
  }

  .underlineHover:after {
    display: block;
    left: 0;
    bottom: -10px;
    width: 0;
    height: 2px;
    background-color: #F6315C;
    content: "";
    transition: width 0.2s;
  }

  .underlineHover:hover {
    color: #F6315C;
  }

  .underlineHover:hover:after{
    width: 100%;
  }
  .azul{
    color:#F6315C;
    font-weight: 600;
  }
  .azulLeve{
    font-family: 'TT Norms' !important;
    font-display: swap !important;
    color:#F6315C;
    font-weight: 400;
    font-size:45px;
    text-align: left;
  }
  .azulLeveA{ 
    font-family: 'TT Norms' !important;
    font-display: swap !important;
    font-style: bold;
    font-weight: 600;
    color: #414141;
    border:none;
    padding:0;
    font-size:45px;
    background-color: transparent;

  }
  #welcomeBack{
    max-width:450px;
    padding-bottom: 2rem;
  }
  .blank{
    font-weight: 0;
    color: #414141;
  }
  .blank a{
    font-weight: 600;
    color: #F6315C;
    
  }
  .blank a:hover{
    color: #F6315C;
  }
  .blank a:active{
    color: #F6315C;
  }
  /* OTHERS */

  *:focus {
    outline: none;
  }

  #icon {
    width:60%;
  }
}


#fot{
  margin-bottom: 0rem;
}

.errorLogin .modal-content{
  width: 400px;
  height: 250px;
  border-radius: 22px;
  background-color: #ededed;
  margin-top:300px;
  margin-left: 20px;
}
.errorLogin .modal-content .closeButton{
  background-color: #F6315C;
  outline: none !important;
  border: none !important;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
}
.errorLogin .modal-content .closeButton:hover{
  background-color: #8c8c8c;
}

.error{
  color: #8c8c8c;
  text-align: center;
  font-size: 20px;
  margin-top:10px;
}

.error i{
  font-size: 40px;
  margin-bottom: 30px;
}

@media only screen and (min-width:2000px){
  .LoginForm{
    margin-top:5rem;
    margin-left: 750px;
  }
  .errorLogin .modal-content{
    margin-top:450px;
    margin-left: 65px;
  }
}

@media only screen and (max-width:1700px){
  .LoginForm{
    margin-top:5rem;
    margin-left: 590px;
  }
  .errorLogin .modal-content{
    margin-top:450px;
    margin-left: 65px;
  }
}

@media only screen and (max-width:1600px){
  .LoginForm{
    margin-top:5rem;
    margin-left: 550px;
  }
  .errorLogin .modal-content{
    margin-top:380px;
    margin-left: 80px;
  }
}

@media only screen and (max-width: 1536px)
{
  #welcomeBack{
    width:500px;
    margin-top:20px;
    padding-bottom: 10px;
  }
  .azulLeve{
    font-size:38px !important;
    text-align: left;
    margin-top:0px;
    margin-left:30px;
  }
  .azulLeveA{ 
    padding:0;
    font-size:38px !important;

  }
  
.whiteBgLogin{
  background-color: #ededed;
  padding-top:20px;
  padding-bottom:20px;
  padding-left: 40px;
  padding-right: 10px;
  border-radius: 8px;
}

  #formContent {
    border-radius: 10px 10px 10px 10px;
    max-width: 400px !important;
    position: relative;
    text-align: center;
  }
  #formContent .fadeIn.fourth{
    width: 400px;
    text-align: center !important;
    margin-left: 5px;
    padding-left:0px;
    padding-right: 0px;
   }
    .LoginForm{
      margin-top:5rem;
      margin-left: 540px;
    }
  }

  @media only screen and (max-width:1440px){
    .LoginForm{
      margin-top:5rem;
      margin-left: 500px;
    }
    .errorLogin .modal-content{
      margin-top:380px;
      margin-left: 60px;
    }
  }

@media only screen and (max-width: 1376px){
  .LoginForm{
    margin-top:6rem;
    margin-left: 450px;
  }

 
  #welcomeBack{
    max-width:400px;
    padding-bottom: 2rem;
  }
  #formContent {
   margin-top:-1rem;
  }
  #formFooter {
 margin-top:-1rem;


  }

  .errorLogin .modal-content{
    margin-top:350px;
  }
  .testeeeee{
    color:#000000;
  }
 
  }
 @media only screen and (max-width: 1280px){
  .LoginForm{
    margin-left:400px;
  }
  }
@media only screen and (max-width: 1280px) and (max-height:750px){
  
.LoginForm{
  margin-top:5.2rem;
}
  #formFooter {
   margin-top:-2rem;
  }
  .azulLeve{
    font-size:35px !important;
    text-align: left;
    margin-top:0.2rem;
  }
  .azulLeveA{ 
    padding:0;
    font-size:35px !important;

  }

}

@media only screen and (min-width:1100px){
  .warning{
    visibility: hidden;
    display: none !important;
}
.warning .modal-backdrop { 
  display: none !important;
}
.warning {
  display: none !important;
}
.warning .modal-dialog {
  display: none !important;
}
}
@media only screen and (max-width:1024px){
  .LoginContainer{
    display: none;
    visibility: hidden;
  }

  #navbar{
    
    display: none;
    visibility: hidden;
  }
  #formContent {
    display: none;
    visibility: hidden;
   }
   #welcomeBack{
    display: none;
    visibility: hidden;
  }
  .LoginAll{
    background-color:#414141;
    background-image: none;
    background-size:100% 100%;
    width: 100% !important;
    height: 100vh;
    max-height: 100%;
    overflow-y: hidden;
    background-repeat: no-repeat;
  }
  .warning .modal-content{
    width: 500px;
    height: 500px;
    margin-left:0rem;
    margin-top:2rem; 
    background-color: #ededed;
    border-radius: 22px;
}
.warning .modal-content .closeButton{
  background-color: #8c8c8c;
  color: #fff;
  font-weight: 500;
  font-size:30px;
  width: 60px;
  height: 60px;
  border-color:#8c8c8c;
  text-align: center;
  border-radius: 50px;
  margin-left:440px;
  top:-1rem;
  position:absolute;
  z-index: 110;
  opacity: 100;
  box-shadow: none;
  outline: #8c8c8c;
  
}
.warning .modal-content .closeButton:hover{
  background-color: #fff;
  color: #8c8c8c;
  border-color: #fff;
  box-shadow: none !important;
  outline:#fff !important;
}

.warning .modal-content .closeButton:active{
  background-color: #fff;
  color: #8c8c8c;
  border-color: #fff;
  box-shadow: none !important;
  outline:#fff !important;
  
}
.warning .modal-content .closeButton:focus{
  background-color: #fff;
  color: #8c8c8c;
  border-color: #fff;
  box-shadow: none !important;
  outline:#fff !important;
  
}
.warning .aviso i{
  font-size:55px;
}
.warning .aviso{
  font-size: 28px;
  text-align: center;
  margin-top:105px;
  color:#8c8c8c;
}
}


@media only screen and (max-width:600px){
 .warning .modal-content{
   width: 450px;
   height: 450px;
   margin-top:5rem;
   margin-left: 2.5rem;
 }
 .warning .modal-content .closeButton{
  font-size:25px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  margin-left:405px;

 }
 .warning .aviso i{
  font-size:40px;
}
 .warning .aviso{
  font-size: 24px;
  margin-top:130px;
  color:#8c8c8c;
}
}
@media only screen and (max-width:500px){
  #formContent {
    width: 400px;
    text-align: center;
   }

   #formContent .fadeIn.fourth{
    width: 400px;
    text-align: center;
   }
   
  input[type=button], input[type=submit], input[type=reset]  {
    padding: 15px 0px !important;
    text-align: center;
   
  }
  .warning .modal-content{
    width: 420px;
    height: 550px;
    margin-top:11rem;
    margin-left: 2rem;
  }
  .warning .modal-content .closeButton{
   font-size:25px;
   width: 50px;
   height: 50px;
   border-radius: 50px;
   margin-left:365px;
 
  }
  .warning .aviso i{
   font-size:40px;
 }
  .warning .aviso{
   font-size: 24px;
   margin-top:130px;
   color:#8c8c8c;
 }
   
}
@media only screen and (max-width:414px){

  form{
    padding-left: 0;
    padding-right: 0;
  }
  .LoginForm input[type=submit], .LoginForm input[type=reset]{
    padding: 15px 35px;
    margin: 0 0 30px 0;
  }
  .LoginForm input{
    height: 50px !important;
  }
  #welcomeBack{
    width:300px;
    margin-top:28rem;
    padding-bottom: 1rem;
  }
  .azulLeve{
    font-size:30px !important;
    text-align: left;
  }
  .azulLeve a{ 
    font-family: 'TT Norms' !important;
    font-display: swap !important;
    font-style: bold;
    font-weight: 400;
    color: #414141;
    font-size:30px !important;

  }
  .azulLeveA{
    font-size: 30px !important;
  }
  .azulLeve a:hover{
    font-weight: 600;
    color: #fff;
    font-size:10px;

  }
  .underlineHover{
    font-size:12px;
  }
  #formContent {
    width: 350px !important;
    margin-bottom: 33rem;
  }
  #formContent .fadeIn.fourth{
    width: 350px;
    text-align: center;
    margin-left: 0.35rem;
   }
  .azul{
   font-size:12px;
  }
  .azul a{
    font-size:12px;
  }
  .blank{
    font-size:12px;
  }
  .wrapper {
    height: 40vh;
  
  }

  .warning .modal-content{
    width: 350px;
    height: 550px;
    margin-top:11rem;
    margin-left: 1.5rem;
  }
  .warning .modal-content .closeButton{
   font-size:25px;
   width: 50px;
   height: 50px;
   border-radius: 50px;
   margin-left:300px;
 
  }
  .warning .aviso i{
   font-size:40px;
 }
  .warning .aviso{
   font-size: 24px;
   margin-top:140px;
   color:#8c8c8c;
 }

}
@media only screen and (max-width:400px){
   .warning .modal-content{
    width: 350px;
    height: 450px;
    margin-top:8rem;
    margin-left: 0.8rem;
  }
  .warning .modal-content .closeButton{
   font-size:25px;
   width: 45px;
   height: 45px;
   border-radius: 50px;
   margin-left:300px;
 
  }
  .warning .aviso i{
   font-size:40px;
 }
  .warning .aviso{
   font-size: 22px;
   margin-top:100px;
   color:#8c8c8c;
 }

}
@media only screen and (max-width:375px){
  .LoginForm{
    margin-top:7rem;
  }
  #formContent {
    width: 330px !important;
    text-align: center;
   }

   #formContent .fadeIn.fourth{
    width: 300px;
    text-align: center;
   }
   #formFooter {
    margin-top:0rem;
   }
   .warning .modal-content{
    width: 330px;
    height: 400px;
    margin-top:8rem;
    margin-left: 0.8rem;
  }
  .warning .modal-content .closeButton{
   font-size:25px;
   width: 50px;
   height: 50px;
   border-radius: 50px;
   margin-left:280px;
 
  }
  .warning .aviso i{
   font-size:40px;
 }
  .warning .aviso{
   font-size: 24px;
   margin-top:90px;
   color:#8c8c8c;
 }

}
@media only screen and (max-width:360px){
  .LoginForm{
    margin-top:7rem;
  }
  #formContent {
    width: 300px !important;
    text-align: center;
   }

   #formContent .fadeIn.fourth{
    width: 300px;
    text-align: center;
   }

   .warning .modal-content{
    width: 330px;
    height: 400px;
    margin-top:8rem;
    margin-left: 0.5rem;
  }
  .warning .modal-content .closeButton{
   font-size:25px;
   width: 50px;
   height: 50px;
   border-radius: 50px;
   margin-left:270px;
 
  }
  .warning .aviso i{
   font-size:40px;
 }
  .warning .aviso{
   font-size: 24px;
   margin-top:90px;
   color:#8c8c8c;
 }

}
@media only screen and (max-width:280px){
  .LoginForm{
    margin-top:8rem;
  }
  #formContent {
    width: 250px !important;
    text-align: center;
   }

   #formContent .fadeIn.fourth{
    width: 250px;
    text-align: center;
   }
   .azulLeve{
    font-size:25px !important;
    text-align: left;
  }
  #welcomeBack{
   margin-left:3rem;
  
  }
  .azulLeveA{
    font-size: 25px !important;
  }

  
  .warning .modal-content{
    width: 230px;
    height: 400px;
    margin-top:8rem;
    margin-left: 1rem;
  }
  .warning .modal-content .closeButton{
   font-size:25px;
   width: 50px;
   height: 50px;
   border-radius: 50px;
   margin-left:180px;
 
  }
  .warning .aviso i{
   font-size:40px;
 }
  .warning .aviso{
   font-size: 24px;
   margin-top:90px;
   color:#8c8c8c;
 }
}