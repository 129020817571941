.formNegocio{
  margin-left: 360px;
  border: 1px solid gray !important;
  width: 1500px;
  padding:50px;
  margin-top:20px;
  max-height: 800px;
  overflow-y:auto ;
}

.formNegocio::-webkit-scrollbar {
  background-color: #ededed !important;
  width: 10px;
  }
  
  .formNegocio::-webkit-scrollbar-thumb {
  background-color: #D4365A;
  border-radius: 25px;
  height: 5px;
  }
  .formNegocio::-webkit-scrollbar-track {
  background-color: #c8c8c8;
  border: gray !important;
  height: 80%;
  border-radius: 25px;  
  }
  
  .formNegocio::-webkit-scrollbar-track-piece{
  background-color: #c8c8c8 !important;
  }


.formNegocio label{
  font-size: 20px;
  padding: 10px;
}

.tituloNegocio{
  color: #D4365A;
  font-size: 38px;
  margin-left: 900px;
  font-weight: 600;
  margin-top:30px;
}

.inputNegocio{
  width: 1000px;
  height: 40px;
  background-color: #c8c8c8;
  box-shadow: none !important;
  border: solid 1px gray;
}

.inputNegocio:focus{
  outline: none !important;
  box-shadow: none !important;
}

.buttonNegocio{
  margin-left:305px;
  width: 160px;
  background-color: #D4365A;
  border: none !important;
  border-radius: 5px;
  height:40px;
}
.buttonNegocio::placeholder{
  color:#fff;
  font-weight: 500;
  text-align: center;
}

.buttonNegocio:focus::placeholder{
  color:#8c8c8c;
  font-weight: 500;
  text-align: center;
}

.buttonNegocio:focus{
  background-color: #fff;
  border:none !important;
  outline: none !important;
}

.selectNegocios select{
  width: 1000px;
  background-color: #c8c8c8;
  border: solid 1px gray;
}

.plusMembros{
  margin-left:305px;
  background-color: #D4365A;
  width: 160px;
  border:none !important;
  height:40px;
  color:#fff;
  font-weight: 500;
  border-radius: 5px;
}

.plusMembros::placeholder{
  color:#fff;
  font-weight: 500;
  text-align: center;
}

.plusMembros:focus::placeholder{
  color:#8c8c8c;
  font-weight: 500;
  text-align: center;
}

.plusMembros:focus{
  background-color: #c8c8c8;
  border:none !important;
  outline: none !important;
}

.btnEnviarNeg{
  background-color: #D4365A;
  color:#fff;
  box-shadow: none !important;
  outline: none !important;
  border: none;
  height:45px;
  font-size: 20px;
  margin-left: 600px;
}

.downloadContrato{
  font-size: 40px;
  color:#D4365A;
  text-align: center;
}
.downloadContrato i a{
  color:#D4365A !important;
}
.downloadContrato i{
  color:#D4365A !important;
}
.nomeContrato{
  text-align: center;
}

.arquivosModal .modal-content{
  width: 400px !important;
  background-color: #c8c8c8;
}
.arquivosModal .modal-body{
  margin-left:-50px;
}

.arquivosModal .modal-content .closeButton{
  background-color: #D4365A;
  border: none;
  box-sizing: none;
}

.contratos{
  background-color: #D4365A;
  border: none !important;
  border-radius: 8px;
  width: 75px;
  color:#fff;
  font-weight: 500;
  margin-left: 10px;
}


@media only screen and (max-width:1700px){
.formNegocio{
  margin-left: 390px;
  border: 1px solid gray !important;
  width: 1200px;
  padding:50px;
  margin-top:20px;
  max-height: 800px;
  overflow-y:auto ;
}
.inputNegocio{
  width: 800px;
  height: 40px;
  background-color: #c8c8c8;
  box-shadow: none !important;
  border: solid 1px gray;
}

.buttonNegocio{
  margin-left:245px;
  width: 130px;
  background-color: #D4365A;
  border: none !important;
  border-radius: 5px;
  height:40px;
}
.selectNegocios select{
  width: 800px;
  background-color: #c8c8c8;
  border: solid 1px gray;
}
.plusMembros{
  margin-left:245px;
  background-color: #D4365A;
  width: 130px;
  border:none !important;
  height:40px;
  color:#fff;
  font-weight: 500;
  border-radius: 5px;
}
.btnEnviarNeg{
  margin-left: 500px;
}
.containerEquipe{
  font-size: 16px !important;
}
}


@media only screen and (max-width:1620px){
  .formNegocio{
    margin-left: 400px;
    border: 1px solid gray !important;
    width: 1100px;
    padding:50px;
    margin-top:20px;
    max-height: 700px;
    overflow-y:auto ;
  }
  .inputNegocio{
    width: 700px;
    height: 40px;
    background-color: #c8c8c8;
    box-shadow: none !important;
    border: solid 1px gray;
  }
  .buttonNegocio{
    margin-left:205px;
    width: 130px;
  }
  .selectNegocios select{
    width: 700px;
    background-color: #c8c8c8;
    border: solid 1px gray;
  }
  .plusMembros{
    margin-left:205px;
    background-color: #D4365A;
    width: 130px;
  }
  .tituloNegocio{
    margin-left: 800px;
  }
  .btnEnviarNeg{
    margin-left: 420px;
  }
  .containerEquipe{
    font-size: 15px !important;
  }
}

@media only screen and (max-width:1550px){
  .formNegocio{
    margin-left: 370px;
  }
}
@media only screen and (max-width:1450px){
  .formNegocio{
    margin-left: 320px;
  }
  .tituloNegocio{
    margin-left: 750px;
    font-size: 30px;
  }
}
@media only screen and (max-width:1370px){
  .formNegocio{
    margin-left: 365px;
    width: 950px;
  }
  .tituloNegocio{
    margin-left: 750px;
    font-size: 30px;
  }
  .inputNegocio{
    width: 600px;
  }
  .selectNegocios select{
    width: 600px;
  }
  .buttonNegocio{
    margin-left:175px;
    width: 130px;
  }
  .plusMembros{
    margin-left:175px;
    background-color: #D4365A;
    width: 130px;
  }
  .btnEnviarNeg{
    margin-left: 360px;
  }
  .containerEquipe{
    font-size: 13px !important;
  }
}
@media only screen and (max-width:1280px){
  .formNegocio{
    margin-left: 365px;
    width: 850px;
    height:500px;
  }
  .tituloNegocio{
    margin-left: 700px;
    font-size: 25px;
  }
  .inputNegocio{
    width: 500px;
  }
  .buttonNegocio{
    margin-left:135px;
    font-size: 13px;
    width: 100px;
  }
  .selectNegocios select{
    width: 500px;
    margin-left:0px !important;
  }
  .formNegocio label{
    font-size: 17px;
  }
  
  .plusMembros{
    margin-left:135px;
    font-size: 13px;
    width: 100px;
  }
  .btnEnviarNeg{
    margin-left: 290px;
    font-size: 18px;
  }
}